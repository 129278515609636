import { SyncButton, useFlags } from '@insidedesk/tuxedo';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';
import {
  FormContainer,
  SelectElement,
  TextareaAutosizeElement,
} from 'react-hook-form-mui';
import { DialogToolbar } from '.';

const SUBJECT_OPTIONS = [
  'Claim Posted, still Open',
  'Patient/Subscriber info incorrect',
  'Claim not updating',
  'Claim in Incorrect Category',
  'Insurance/Payer Incorrect',
  'Payment amount incorrect',
  'Claim is on File with Payer',
].map((option) => ({ id: option, label: option }));

const DEPRECATED_SUBJECT_OPTIONS = [
  'Eob incorrect',
  'Claim in incorrect tab',
  'Other',
].map((option) => ({ id: option, label: option }));

export type FeedbackFormValues = { subject: string; body: string };

export default function FeedbackDialog({
  open,
  submitting,
  onClose,
  onSubmit,
}: {
  open: boolean;
  submitting: boolean;
  onClose: () => void;
  onSubmit: (value: FeedbackFormValues) => void;
}) {
  const flags = useFlags();

  return (
    <Dialog
      data-testid={open ? 'feedback-dialog' : undefined}
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      PaperProps={{ sx: { padding: 2 } }}
    >
      <DialogToolbar
        onClose={onClose}
        text={['Feedback']}
        fontSize={22}
        icon={<FeedbackOutlinedIcon />}
      />
      <FormContainer<FeedbackFormValues>
        onSuccess={onSubmit}
        defaultValues={{ subject: '', body: '' }}
      >
        <DialogContent sx={{ paddingTop: 0 }}>
          <Stack spacing={2}>
            <SelectElement
              name='subject'
              required
              label='Please Select an Issue'
              options={
                flags.unmatchResponse
                  ? SUBJECT_OPTIONS
                  : [...SUBJECT_OPTIONS, ...DEPRECATED_SUBJECT_OPTIONS]
              }
              fullWidth
              sx={{ mt: 1 }}
            />
            <TextareaAutosizeElement
              name='body'
              required
              placeholder='Please enter your feedback here *'
              fullWidth
              multiline
              rows={8}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>
          <SyncButton type='submit' variant='contained' syncing={submitting}>
            {submitting ? 'Sending' : 'Send'}
          </SyncButton>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}
