import { Close } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';

export default function ModalCloseButton({ onClick }: { onClick: () => void }) {
  const theme = useTheme();
  return (
    <IconButton
      color='primary'
      onClick={onClick}
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
      }}
    >
      <Close />
    </IconButton>
  );
}
