import { ThemeColorOptions } from '@insidedesk/tuxedo';
import { alpha, Box, PaletteColor, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { ClaimDetails } from '../../../types';
import { getArAgeColor, getArAgeText } from '../../../utils';
import './ArAgeIndicator.scss';

function splitColorVariant(
  color: `${ThemeColorOptions}.${keyof PaletteColor}`,
) {
  return color.split('.', 2) as [ThemeColorOptions, keyof PaletteColor];
}

export default function ArAgeIndicator({
  claimDetails,
}: {
  claimDetails: ClaimDetails;
}) {
  const theme = useTheme();

  const postedDate = dayjs(claimDetails.posted_date ?? '');
  const serviceDate = dayjs(claimDetails.service_date_start ?? '');
  const arAge = (postedDate.isValid() ? postedDate : dayjs()).diff(
    serviceDate,
    'days',
  );
  if (Number.isNaN(arAge)) return null;

  const color = getArAgeColor(arAge);
  const [paletteKey, paletteVariant] = splitColorVariant(color);
  const backgroundColor = theme.palette[paletteKey][paletteVariant];

  const text = getArAgeText(arAge);
  return (
    <Box
      className='ar-age-indicator'
      sx={{
        color,
        backgroundColor: alpha(backgroundColor, 0.1),
        float: 'right',
      }}
    >
      Claim Aged: {text} days
    </Box>
  );
}
