import { capitalizeWord, removeNullish, User } from '@insidedesk/tuxedo';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import { PREVIEW_CONFIG } from '../config';
import { Address } from '../types';

dayjs.extend(UTC);

const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});
const TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
});
const CURRENCY_FORMAT = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

export function formatDate(dateString: dayjs.Dayjs | string | null) {
  if (dateString === null) return 'N/A';
  const date = typeof dateString === 'object' ? dateString : dayjs(dateString);
  return DATE_FORMAT.format(date.toDate());
}

export function formatTime(
  dateString: dayjs.Dayjs | string | null,
  output: 'date' | 'time' = 'time',
) {
  if (dateString === null) return 'N/A';
  const date =
    typeof dateString === 'object' ? dateString : dayjs.utc(dateString);
  if (output === 'date') {
    return DATE_FORMAT.format(date.toDate());
  }
  return TIME_FORMAT.format(date.toDate());
}

export function formatZipcode(zipcode: string | null) {
  if (zipcode === null || zipcode === '00000') {
    return 'N/A';
  }
  return zipcode;
}

export function formatAddress(address: Address | null) {
  if (address === null) return 'N/A';
  return `${address.auxillary}, ${address.city}, ${address.state}, ${address.zipcode}`;
}

export function formatTaxId(taxId: string | null) {
  if (taxId === null) return 'N/A';
  let formattedTaxId = taxId.replaceAll('-', '');
  formattedTaxId = `${formattedTaxId.slice(0, 2)}-${formattedTaxId.slice(2)}`;
  return formattedTaxId;
}

function capitalizeName(name: string) {
  return name
    .toLowerCase()
    .split(' ')
    .map((token) => capitalizeWord(token))
    .join(' ')
    .split('-')
    .map((token) => capitalizeWord(token))
    .join('-');
}

export function formatFullName(
  firstName: string | null,
  lastName: string | null,
) {
  return `${capitalizeName((lastName ?? '').trim())}, ${capitalizeName(
    (firstName ?? '').trim(),
  )}`;
}

// XXX: Update formatCurrency to handle other edge cases with strings, and other money edge case (consider adding a light weight library that handles this robustly).
// Also, the default returned value for null/undefined cases is '-', consider making this configurable and setting the '-' hyphen as the fallback.
export function formatCurrency(
  amount: string | number | null,
  { hideZeros = true } = {},
) {
  if (amount === null) return '-';
  if (amount === 0 && hideZeros) return '-';
  return CURRENCY_FORMAT.format(
    typeof amount === 'number' ? amount : parseFloat(amount),
  );
}

export function formatInsurerName(name: string) {
  return capitalizeName(name);
}

export function formatUpgradeRequestSupportUrl(
  user: User,
  facilityName?: string,
) {
  const baseUrl = PREVIEW_CONFIG.upgradeUrl;

  const params = new URLSearchParams(
    removeNullish({
      subject: 'Upgrade to assist',
      content:
        'I would like to upgrade my subscription to include claims, claim responses, EOBs, and workflows.',
      location_name: facilityName,
      firstname: user.first_name,
      lastname: user.last_name,
      company: user.client.name,
      email: user.email,
    }),
  );

  const url = new URL(baseUrl);
  url.search = params.toString();

  return url.toString();
}
