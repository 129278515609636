import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  alpha,
  Button,
  buttonBaseClasses,
  ButtonProps,
  getContrastRatio,
  IconButton,
  IconButtonProps,
  SvgIcon,
  SvgIconProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ElementType, ReactNode } from 'react';

type DialButtonProps<
  IconOnly extends boolean = false,
  Component extends ElementType = 'button',
> = {
  buttonText: ReactNode;
  forceEnabledStyle?: boolean;
  gradient: [string, string];
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconOnly?: IconOnly;
} & (IconOnly extends true
  ? IconButtonProps<Component>
  : ButtonProps<Component>);

export default function DialButton<
  IconOnly extends boolean = false,
  Component extends ElementType = 'button',
>(props: DialButtonProps<IconOnly, Component>) {
  const {
    buttonText,
    forceEnabledStyle = false,
    gradient,
    Icon,
    iconOnly = false,
    ...rest
  } = props;
  const theme = useTheme();

  /**
   * Normally Buttons can determine the appropriate color to use from the
   * background color. Since the background here is a gradient the color cannot
   * be properly determined so this manually replicates the way to determine the
   * contrast color.
   */
  const color =
    getContrastRatio(theme.palette.primary.main, gradient[1]) > 4.5
      ? theme.palette.primary.main
      : '#ffffff';

  let ButtonComponent = Button;
  let iconOnlyIconProps: Partial<SvgIconProps> = {};
  if (iconOnly) {
    ButtonComponent = IconButton;
    iconOnlyIconProps = {
      sx: { margin: 1, padding: '3px 5px 2px 3px', pointerEvents: 'auto' },
    };
  }

  return (
    <ButtonComponent
      {...rest}
      sx={[
        {
          background: `linear-gradient(${gradient[0]}, ${gradient[1]})`,
          color,
          paddingY: 1,
          textTransform: 'uppercase',
          [`&.${buttonBaseClasses.disabled}`]: {
            color: forceEnabledStyle ? color : alpha(color, 0.26),
          },
        },
        iconOnly && {
          padding: 1,
          fontSize: 'small',
          width: '40px',
          height: '40px',
          ml: 0.5,
        },
        ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
      ]}
    >
      <SvgIcon fontSize={rest.size ?? 'small'} {...iconOnlyIconProps}>
        <Icon />
      </SvgIcon>
      {!iconOnly ? (
        <Typography
          sx={{ color: 'inherit', ml: 2, fontWeight: variables.weightMedium }}
          component='span'
        >
          {buttonText}
        </Typography>
      ) : (
        <span className='visually-hidden'>{buttonText}</span>
      )}
    </ButtonComponent>
  );
}
