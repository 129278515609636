import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { ClaimDetails, PatchClaimVariables, Writeable } from '../types';
import { MutationBuilder } from '../utils';

export default function usePatchClaimDetails(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useClientAPIMutationFn<void, PatchClaimVariables>(
    `claims/${claimId}`,
    { method: 'PATCH' },
  );
  const claimDetailsKey = ['claims', claimId, 'details'];
  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      claimDetailsKey,
      produce(
        (draft: Writeable<ClaimDetails>, variables: PatchClaimVariables) => {
          draft.categories = variables.categories ?? draft.categories;
        },
      ),
    )
    .invalidate(['claimList'])
    .configure();
  return useMutation(options);
}
