import { useFlags } from '@insidedesk/tuxedo';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import FolderOffIcon from '@mui/icons-material/FolderOffOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReportErroredIcon from '@mui/icons-material/ReportOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import UpdateIcon from '@mui/icons-material/Update';
import { SvgIcon, Tooltip } from '@mui/material';
// Not exported from package
import { CSSProperties, FunctionComponent } from 'react';
import {
  ClaimNotSentIcon,
  FailedCallIcon,
  InfoRequestedIcon,
  InvalidDataIcon,
  LoginIssuesIcon,
  PaidOkIcon,
  PaidOverIcon,
  PaidUnderIcon,
  PaidZeroIcon,
  PatientNotFoundIcon,
  UpdateMFAIcon,
  ValidationErrorIcon,
} from '../../../assets';
import { UIStatus } from '../../../types';

export default function StatusIcon({
  paidSubscriber,
  status,
  statusCode,
  tooltipInfo,
}: {
  paidSubscriber: boolean;
  status: string;
  statusCode: number;
  tooltipInfo?: string;
}) {
  const flags = useFlags();
  let Component: FunctionComponent;
  let title = status;
  let color: string | undefined;
  let needsWrapper = true;
  let customProps: CSSProperties = {};
  switch (statusCode) {
    case UIStatus.RECEIVED:
      Component = HourglassTopIcon;
      color = 'black';
      break;
    case UIStatus.PROCESSING:
    case UIStatus.CLEARINGHOUSE_PENDING_SUBMISSION:
    case UIStatus.CLEARINGHOUSE_RESUBMITTED:
    case UIStatus.CLEARINGHOUSE_RECEIVED:
    case UIStatus.CLEARINGHOUSE_SUBMITTED:
    case UIStatus.CLEARINGHOUSE_VALIDATING:
      Component = HourglassTopIcon;
      color = 'warning.dark';
      break;
    case UIStatus.PMS_DATA_ONLY:
      Component = SearchOffIcon;
      color = 'primary.main';
      break;
    case UIStatus.PAID_AS_EXPECTED:
      Component = PaidOkIcon;
      color = 'success.main';
      needsWrapper = false;
      break;
    case UIStatus.PAID_NOT_AS_EXPECTED:
    case UIStatus.PAID_UNKNOWN:
    case UIStatus.CLEARINGHOUSE_PROCESSED:
    case UIStatus.CLEARINGHOUSE_USER_RESOLVED:
      Component = RequestQuoteIcon;
      color = 'primary.main';
      break;
    case UIStatus.PAID_ZERO:
      Component = PaidZeroIcon;
      needsWrapper = false;
      break;
    case UIStatus.NO_CLAIM_YET:
      Component = FolderOffIcon;
      color = 'error.dark';
      break;
    case UIStatus.PATIENT_NOT_FOUND:
      Component = PatientNotFoundIcon;
      color = 'error.dark';
      break;
    case UIStatus.FACILITY_ISSUE:
      Component = DomainDisabledIcon;
      color = 'error.dark';
      break;
    case UIStatus.INVALID_DATA:
    case UIStatus.CLEARINGHOUSE_REJECTED:
      Component = InvalidDataIcon;
      break;
    case UIStatus.LOGIN_ISSUE:
      Component = LoginIssuesIcon;
      break;
    case UIStatus.RETRYING:
      Component = UpdateIcon;
      color = 'primary.main';
      break;
    case UIStatus.INFO_REQUESTED:
    case UIStatus.CLEARINGHOUSE_INFO_REQUESTED:
    case UIStatus.CLEARINGHOUSE_SUSPENDED:
      Component = InfoRequestedIcon;
      break;
    case UIStatus.PAID_OVER_EXPECTED:
      Component = PaidOverIcon;
      needsWrapper = false;
      break;
    case UIStatus.PAID_UNDER_EXPECTED:
      Component = PaidUnderIcon;
      needsWrapper = false;
      break;
    case UIStatus.PORTAL_ACTION:
      Component = ReportErroredIcon;
      color = 'error.dark';
      break;
    case UIStatus.UPDATE_MFA:
      Component = UpdateMFAIcon;
      color = 'error.dark';
      break;
    case UIStatus.CLAIM_BATCHED_NOT_SENT:
      Component = ClaimNotSentIcon;
      color = 'warning.dark';
      break;
    case UIStatus.CLEARINGHOUSE_VALIDATION_ERROR_IDENTIFIED:
    case UIStatus.CLEARINGHOUSE_VALIDATION_ERROR:
      Component = ValidationErrorIcon;
      color = 'error.dark';
      break;
    case UIStatus.CLAIM_CALL_FAILED:
      Component = FailedCallIcon;
      customProps = { fontSize: '18px' };
      color = 'error.dark';
      break;
    default:
      Component = FolderIcon;
      title = 'Claim';
      color = 'secondary.dark';
  }

  if (paidSubscriber) {
    Component = CurrencyExchangeIcon;
    title = 'Paid Subscriber';
    color = 'warning.dark';
    needsWrapper = true;
  }

  let svgComponent = <Component />;
  if (needsWrapper) {
    svgComponent = (
      <SvgIcon component={Component} sx={{ color, ...customProps }} />
    );
  }

  return (
    <Tooltip
      title={`${title}${tooltipInfo ? ` ${tooltipInfo}` : ''}`}
      arrow
      placement={flags.tooltipPlacementTop ? 'top' : 'bottom'}
    >
      {svgComponent}
    </Tooltip>
  );
}
