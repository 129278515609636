import '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
/**
 * XXX This may be a misuse of mui theming. I couldn't figure out how to make
 * this theme only include the palette and the main theme reference that
 * palette.
 */
export default function AccentThemeProvider({
  color,
  children,
}: PropsWithChildren<{
  color: 'commercial' | 'medicaid' | 'ortho' | 'closed';
}>) {
  const parentTheme = useTheme();
  const theme = useMemo(() => {
    const accent = parentTheme.palette[color];
    return createTheme(
      {
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: variables.borderRadius,
              },
            },
            variants: [
              {
                props: { color: 'accent-primary' },
                style: {
                  '& .MuiCardHeader-root': {
                    color: 'white',
                    backgroundColor: accent.dark,
                  },
                },
              },
              {
                props: { variant: 'outlined', color: 'accent-primary' },
                style: {
                  borderColor: accent.dark,
                },
              },
              {
                props: { color: 'accent-secondary' },
                style: {
                  '& .MuiCardHeader-root': {
                    color: accent.dark,
                    backgroundColor: accent.light,
                  },
                },
              },
              {
                props: { variant: 'outlined', color: 'accent-secondary' },
                style: {
                  borderColor: accent.main,
                },
              },
            ],
          },
        },
      },
      parentTheme,
    );
  }, [color, parentTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
