import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { TabList, TabListProps } from '@mui/lab';
import { Box, styled, useTheme } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import '../../theme';

const TAB_HEIGHT = '40px';
const TAB_SPACING = 1;

/** Tabs don't support variants */
const StyledTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    borderTopLeftRadius: variables.borderRadius,
    borderTopRightRadius: variables.borderRadius,
  },
  isolation: 'isolate',
  minHeight: TAB_HEIGHT,
  height: TAB_HEIGHT,
  '& .MuiTab-root': {
    '&.Mui-selected': {
      color: 'white',
    },
    transition: 'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
    borderTopRightRadius: variables.borderRadius,
    borderTopLeftRadius: variables.borderRadius,
    minHeight: TAB_HEIGHT,
    height: TAB_HEIGHT,
    zIndex: 1,
    marginLeft: `calc(${theme.spacing(TAB_SPACING)} / 2)`,
    marginRight: `calc(${theme.spacing(TAB_SPACING)} / 2)`,
  },
}));

export default function RoundedTabList(
  props: TabListProps & { bolded?: boolean },
) {
  const { bolded = false, ...rest } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const [rects, setRects] = useState<DOMRect[]>([]);
  const theme = useTheme();

  // I'm so, so sorry about this
  useLayoutEffect(() => {
    const newRects = Array.from(
      ref.current?.getElementsByClassName('MuiTab-root') ?? [],
    ).map((node) => node.getBoundingClientRect());
    setRects(newRects);
  }, []);

  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        position='relative'
        top={TAB_HEIGHT}
        mt={`-${TAB_HEIGHT}`}
      >
        {rects.map((rect, index) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`${rect.x},${rect.y},${rect.width},${rect.height}-${index}`}
            display='inline-block'
            width={rect.width}
            height={TAB_HEIGHT}
            sx={{
              background: 'white',
              borderTopRightRadius: variables.borderRadius,
              borderTopLeftRadius: variables.borderRadius,
              marginLeft: `calc(${theme.spacing(TAB_SPACING)} / 2)`,
              marginRight: `calc(${theme.spacing(TAB_SPACING)} / 2)`,
            }}
          />
        ))}
      </Box>
      <StyledTabList
        ref={ref}
        {...rest}
        sx={{
          ...rest.sx,
          '& .MuiTab-root': {
            fontWeight: bolded
              ? variables.weightSemiBold
              : variables.weightMedium,
          },
        }}
      />
    </>
  );
}
