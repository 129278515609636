import {
  Dot,
  LabelledCell,
  MaskFullStory,
  PHI,
  ProductPreviewContext,
  useFlags,
  useUserProfile,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  PopoverPosition,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  ElementType,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { PhoneNumber, PortalLink } from '../..';
import { ClaimInfo, StatusMapping, UIStatus } from '../../../types';

import {
  formatCurrency,
  formatDate,
  formatFullName,
  formatInsurerName,
  formatTime,
  getArAgeColor,
  getArAgeText,
  isSnoozed,
} from '../../../utils';
import './ClaimInfoCard.scss';
import ContextMenu from './ContextMenu';
import StatusIcon from './StatusIcon';

const TRUNCATE_PROPS: TypographyProps<ElementType> = {
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

function getClaimStatusHighlight(claim: ClaimInfo) {
  const status = claim.status_code;

  const credentialIssue =
    status === UIStatus.LOGIN_ISSUE || status === UIStatus.UPDATE_MFA;

  const claimAge = dayjs().diff(claim.service_date_start, 'days');
  const snoozed = isSnoozed(claim.claim_ui_state);
  const updated =
    Boolean(claim.claim_ui_state.unread_first_updated) &&
    Boolean(claim.last_updated) &&
    dayjs(claim.last_updated) >= dayjs(claim.claim_ui_state.last_viewed);

  const paidAsExpected = status === UIStatus.PAID_AS_EXPECTED;

  if (credentialIssue) {
    return 'credentialIssue';
  }
  if (updated) {
    return 'updated';
  }
  if (snoozed) {
    return 'snoozed';
  }
  if (paidAsExpected) {
    return 'paid';
  }
  if (claimAge >= 365) {
    return 'expired';
  }
  if (claimAge >= 305) {
    return 'old';
  }
  if (claim.claim_ui_state.unread === false) {
    return 'read';
  }
  return 'unread';
}

export default function ClaimInfoCard(props: {
  claim: ClaimInfo;
  statusMapping: StatusMapping;
  overallIndex: number;
  callButton?: ReactNode;
}) {
  const { claim, statusMapping, overallIndex, callButton } = props;

  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();
  const { client } = useUserProfile();
  const { preview } = useContext(ProductPreviewContext);

  const flags = useFlags();

  const [contextMenuAnchor, setContextMenuAnchor] =
    useState<PopoverPosition | null>(null);

  const statusHighlight = getClaimStatusHighlight(claim);

  const claimAge = dayjs().diff(claim.service_date_start, 'days');
  const arAgeText = getArAgeText(claimAge);
  const arAgeColor = getArAgeColor(claimAge);
  let iconTooltipSupplement: string | undefined;
  if (claimAge >= 365) {
    iconTooltipSupplement = 'expired';
  } else if (claimAge >= 305) {
    iconTooltipSupplement = 'expiring soon';
  }

  const toggleExpanded = (e: ReactMouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', minWidth: 'fit-content' }}
    >
      <Dot
        aria-label='claim is unread'
        sx={{
          marginRight: '5px',
          visibility: claim.claim_ui_state.unread ? 'visible' : 'hidden',
        }}
      />
      <Card
        data-testid={`claim-card-${claim.id}`}
        aria-label={statusHighlight}
        variant='outlined'
        className={`claim-info-card ${statusHighlight}-highlight ${
          claim.claim_ui_state.unread ? 'unread' : ''
        }`}
        onClick={() => {
          navigate(`/claim/${client.name}/${claim.id}`, {
            state: { overallIndex, inPreview: preview },
          });
        }}
        onContextMenu={(e) => {
          setContextMenuAnchor({ left: e.clientX, top: e.clientY });
          e.preventDefault();
        }}
        sx={{ flex: '1' }}
      >
        <CardContent
          className='main-info'
          aria-label={`Claim #${overallIndex + 1}`}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(300px, 3fr) 4fr 5fr',
            gap: 2,
            px: 2,
            py: 1,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'minmax(50px, 1fr) 5fr 5fr',
              gap: 1,
            }}
          >
            <Stack
              direction='row'
              sx={{ alignItems: 'center', gap: 0.5, '& > *': { flex: 'auto' } }}
            >
              <Typography variant='dataValue' sx={{ textAlign: 'center' }}>
                {overallIndex + 1}
              </Typography>
              <StatusIcon
                paidSubscriber={claim.categories.includes('paid_subscriber')}
                status={statusMapping[claim.status_code]?.name ?? ''}
                statusCode={claim.status_code}
                tooltipInfo={
                  iconTooltipSupplement && `and ${iconTooltipSupplement}`
                }
              />
            </Stack>
            <LabelledCell
              label='Patient'
              value={
                <MaskFullStory>
                  <PHI>
                    {formatFullName(
                      claim.patient?.first_name,
                      claim.patient?.last_name,
                    )}
                  </PHI>
                </MaskFullStory>
              }
              valueProps={{
                color: 'primary.main',
                fontWeight: variables.weightSemiBold,
              }}
            />
            <LabelledCell
              label='Subscriber'
              value={
                <MaskFullStory>
                  <PHI>
                    {formatFullName(
                      claim.subscriber?.first_name,
                      claim.subscriber.last_name,
                    )}
                  </PHI>
                </MaskFullStory>
              }
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                'minmax(80px, 2fr) minmax(80px, 3fr) minmax(60px, 2fr) 3fr minmax(60px, 2fr)',
              gap: 1.5,
            }}
          >
            <LabelledCell
              label='DoS'
              value={<PHI>{formatDate(claim.service_date_start)}</PHI>}
            />
            <LabelledCell
              label='Insurer'
              description='Insurer'
              value={formatInsurerName(claim.original_insurer_name ?? '-')}
              hideInPreview
            />
            <LabelledCell
              label='AR Age'
              value={arAgeText}
              valueProps={{
                sx: {
                  color: arAgeColor,
                  fontWeight: variables.weightSemiBold,
                },
              }}
            />
            <LabelledCell
              label='Submission Date'
              value={formatDate(claim.submission_date)}
            />
            <LabelledCell
              label='$ Submit'
              value={formatCurrency(claim.claim?.total ?? null)}
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                'repeat(2, minmax(50px, 2fr)) minmax(80px, 2fr) minmax(125px, 3fr) 2fr',
              gap: 1.5,
            }}
          >
            <LabelledCell
              label='$ Expt'
              value={formatCurrency(claim.claim?.expected)}
              hideInPreview
            />
            <LabelledCell
              label='$ Paid'
              value={formatCurrency(claim.claim.paid, {
                hideZeros: !flags.displayPaidZeroValue,
              })}
              hideInPreview
            />
            <LabelledCell
              label='Last Worked'
              value={formatTime(claim.last_worked, 'date')}
              hideInPreview
            />
            <LabelledCell
              label='Workflow Status'
              value={claim.claim_ui_state?.workflow_state?.state ?? '-'}
              hideInPreview
            />
            <Stack
              direction='row'
              sx={{ justifyContent: 'end', alignItems: 'center' }}
            >
              <IconButton
                aria-label='has notes'
                onClick={toggleExpanded}
                sx={{
                  border: variables.borderMain,
                  padding: 1,
                  visibility:
                    claim.notes_preview.length > 0 ? 'visible' : 'hidden',
                }}
              >
                <BookOutlinedIcon color='primary' fontSize='small' />
              </IconButton>
              {callButton}
            </Stack>
          </Box>
          <Box
            sx={{ gridColumn: 'none', display: 'grid', placeItems: 'center' }}
          >
            <IconButton
              aria-label='expand info'
              onClick={toggleExpanded}
              sx={{ margin: 'auto' }}
            >
              {expanded ? (
                <ExpandLessOutlinedIcon />
              ) : (
                <ExpandMoreOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </CardContent>
        <Collapse
          in={expanded}
          unmountOnExit
          className={`${statusHighlight}-highlight `}
        >
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <Stack direction='row' sx={{ justifyContent: 'space-evenly' }}>
              <LabelledCell
                label='Policy ID'
                value={
                  <MaskFullStory>
                    <PHI>{claim.subscriber_insurance_id}</PHI>
                  </MaskFullStory>
                }
              />
              <LabelledCell
                label='Patient DoB'
                value={
                  <MaskFullStory>
                    <PHI>{formatDate(claim.patient.birth_date)}</PHI>
                  </MaskFullStory>
                }
              />
              <LabelledCell
                label='Subscriber DoB'
                value={
                  <MaskFullStory>
                    <PHI>{formatDate(claim.subscriber.birth_date)}</PHI>
                  </MaskFullStory>
                }
              />
              <LabelledCell
                label='Provider Name'
                value={
                  <PHI>
                    {formatFullName(
                      claim.provider.first_name,
                      claim.provider.last_name,
                    )}
                  </PHI>
                }
              />
              <LabelledCell
                label='NPI #'
                value={<PHI>{claim.provider.npi}</PHI>}
              />
              <LabelledCell
                label='Insurance Portal'
                value={
                  <PortalLink
                    href={claim.mapped_collector?.portal_url ?? null}
                    fallback='Unavailable'
                  />
                }
                hideInPreview
                valueProps={TRUNCATE_PROPS}
              />
              <LabelledCell
                label='Insurance Phone #'
                value={
                  <PhoneNumber
                    number={claim.mapped_collector?.phone ?? null}
                    fallback='Unavailable'
                  />
                }
                hideInPreview
              />
            </Stack>
            {claim.notes_preview.length > 0 ? (
              <Grid container sx={{ pt: 1 }}>
                <Grid container item xs={7}>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Typography variant='dataLabel'>Last note: </Typography>
                    <Typography variant='subtitle1'>
                      <PHI>{claim.notes_preview[0]}</PHI>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Collapse>
        <ContextMenu
          claim={claim}
          open={Boolean(contextMenuAnchor)}
          anchorPosition={contextMenuAnchor}
          onClose={() => setContextMenuAnchor(null)}
        />
      </Card>
    </Box>
  );
}
