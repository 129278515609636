import {
  CenterInPage,
  Flag,
  HTTPError,
  KeyboardShortcutProvider,
  LoadingSpinner,
  ProductPreviewProvider,
  provider,
  ProviderStack,
  useFlags,
  useHasPermission,
  useHasRole,
} from '@insidedesk/tuxedo';
import { Box, Grid, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import TraceError from 'trace-error';
import { useEffectOnce } from 'usehooks-ts';
import {
  AdjudicationReasonsCard,
  CallCard,
  ClaimNavigationCard,
  ResponseHistoryCard,
} from '..';
import {
  AccentThemeProvider,
  ActivityCard,
  ClaimStatusCard,
  DocumentsCard,
  MultiSelectCard,
  PMSInfoCard,
  ReportingOnlyBanner,
  TitleBar,
} from '../..';
import { DETAILS_KEYBINDINGS } from '../../../constants';
import { ClaimIdNotFoundError } from '../../../error';
import { useClaimDetails, usePatchUnread } from '../../../hooks';
import {
  ClaimDetails as ClaimDetailsType,
  ClaimResponseEntry,
} from '../../../types';

import { facilityInPreview } from '../../../utils';

function getAccentColor(claimDetails: ClaimDetailsType) {
  const isOrtho = claimDetails.categories.includes('ortho');
  if (claimDetails.deleted) return 'closed';
  if (claimDetails.closed && !isOrtho) return 'closed';
  if (isOrtho && claimDetails.categories.includes('resolved')) return 'closed';
  if (isOrtho) return 'ortho';
  if (claimDetails.categories.includes('medicaid')) return 'medicaid';
  return 'commercial';
}

/**
 * A selected entry state which guarentees that the selected entry is always
 * in the entry list, even if the entry list is refreshed (e.g. on data reload).
 */
export function useSelectedEntryState(entries: readonly ClaimResponseEntry[]) {
  const [oldSelectedEntry, setOldSelectedEntry] = useState(entries[0]);

  const selectedEntry = useMemo(() => {
    const foundEntry = entries.find(
      (entry) => entry.id === oldSelectedEntry?.id,
    );
    return foundEntry ?? entries[0];
  }, [entries, oldSelectedEntry]);

  useEffect(() => {
    setOldSelectedEntry(selectedEntry);
  }, [selectedEntry]);

  return [selectedEntry, setOldSelectedEntry] as const;
}

function useAutoread(claimId: string) {
  const mutation = usePatchUnread(claimId);
  const hasRole = useHasRole();
  const { hasPermission } = useHasPermission();
  useEffectOnce(() => {
    if (hasPermission('write:claim-unread') && !hasRole('internal')) {
      mutation.mutate(false);
    }
  });
}

export default function ClaimDetails({ claimId }: { claimId: string }) {
  const flags = useFlags();
  const { hasPermission } = useHasPermission();

  const { claimDetails, isLoading, error, isError } = useClaimDetails(
    claimId ?? '',
  );

  const claimSuperDialEnabled =
    flags.superDialNotDenticalOnly ||
    claimDetails?.mapped_collector?.name === 'denti-cal';

  const workflowDiscriminator = claimDetails?.categories.includes('ortho')
    ? 'ortho'
    : 'commercial';
  const selectableEntries = useMemo(() => {
    const matches = claimDetails?.best_response.matches ?? [];
    const errors = claimDetails?.best_response.errors ?? [];
    if (matches.length > 0) {
      return Array.from(matches).sort((a, b) =>
        dayjs(b.processed).diff(dayjs(a.processed)),
      );
    }
    return errors.slice(0, 1);
  }, [claimDetails?.best_response]);

  const [selectedEntry, setSelectedEntry] =
    useSelectedEntryState(selectableEntries);

  const titleRef = useRef<HTMLDivElement>();

  if (isError && claimDetails === undefined) {
    if (error instanceof HTTPError && error.response.status === 404) {
      throw new ClaimIdNotFoundError();
    } else if (error instanceof Error) {
      throw new TraceError('Failed to load claim', error);
    } else {
      throw new Error(`Failed to load claim: ${error}`);
    }
  }

  useAutoread(claimId);

  if (isLoading || claimDetails === undefined)
    return (
      <CenterInPage>
        <LoadingSpinner />
        <ReportingOnlyBanner />
      </CenterInPage>
    );

  return (
    <ProviderStack
      providers={[
        provider(AccentThemeProvider, { color: getAccentColor(claimDetails) }),
        provider(KeyboardShortcutProvider, { bindings: DETAILS_KEYBINDINGS }),
        provider(ProductPreviewProvider, {
          preview:
            Boolean(flags.reportingOnlyProductPreview) &&
            facilityInPreview(claimDetails.facility),
        }),
      ]}
    >
      {/* See https://github.com/mui/material-ui/issues/36335 */}
      <Grid
        container
        spacing={2}
        sx={{ padding: 4 }}
        data-testid='claim-details-page'
      >
        <Grid item xs={9} sx={{ position: 'relative', isolation: 'isolate' }}>
          <Box
            ref={titleRef}
            sx={{
              backgroundColor: 'background.default',
              paddingY: 1,
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}
          >
            <TitleBar claimDetails={claimDetails} />
          </Box>
          <PMSInfoCard
            claimDetails={claimDetails}
            offset={titleRef.current?.getBoundingClientRect().height}
          />
          <Stack spacing={2} sx={{ paddingTop: 2 }}>
            <MultiSelectCard
              entries={selectableEntries}
              selectedEntry={selectedEntry}
              onSelectedEntryChange={(e, entry) => setSelectedEntry(entry)}
            />
            <DocumentsCard claimDetails={claimDetails} entry={selectedEntry} />
            <AdjudicationReasonsCard
              claimDetails={claimDetails}
              entry={selectedEntry}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={4}>
            <ResponseHistoryCard claimDetails={claimDetails} />
            <Flag flagName='superDialStatus'>
              {claimSuperDialEnabled && (
                <CallCard claimDetails={claimDetails} />
              )}
            </Flag>
            {hasPermission('read:rcm') && (
              <ClaimStatusCard
                claimId={claimId}
                workflowDiscriminator={workflowDiscriminator}
              />
            )}
            <ClaimNavigationCard />
            <ActivityCard claimId={claimId} />
          </Stack>
        </Grid>
      </Grid>
      <ReportingOnlyBanner
        inPreview={facilityInPreview(claimDetails.facility)}
        facilityName={claimDetails.facility.name ?? undefined}
      />
    </ProviderStack>
  );
}
