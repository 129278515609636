import { DialogInParent, SyncProgress } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useId } from 'react';
import { formatTime } from '../../../utils';

export type ErrorState = undefined | 'expired' | 'not_loaded';

export function useDataExpired(queries: UseQueryResult[]) {
  const isMissingData = queries.some((query) => query.data === undefined);
  const isFetching = queries.some((query) => query.isFetching);
  const isError = queries.some(
    (query) => query.errorUpdatedAt > query.dataUpdatedAt,
  );
  const isPaused = queries.some((query) => query.isPaused);

  const lastUpdatedAt = queries
    .map((query) => Math.max(query.errorUpdatedAt, query.dataUpdatedAt))
    .reduce((a, b) => Math.max(a, b));
  const lastUpdatedDate = dayjs(lastUpdatedAt);

  const refresh = useCallback(() => {
    queries.forEach((query) => query.refetch({ cancelRefetch: true }));
  }, [queries]);

  const dialogOpen = isError || isPaused;
  let error: ErrorState;
  if (dialogOpen) error = isMissingData ? 'not_loaded' : 'expired';

  return {
    dialogOpen,
    error,
    syncing: isFetching,
    refresh,
    lastUpdatedDate,
  };
}

type DataExpiredContext = ReturnType<typeof useDataExpired>;

/**
 * Popup within parent to show if query data is not yet loaded / expired. Uses
 * a `DialogInParent` with all the associated limitations.
 */
export default function DataExpiredPopup({
  context,
}: {
  context: DataExpiredContext;
}) {
  const descriptionId = useId();
  const { dialogOpen, error, syncing, refresh, lastUpdatedDate } = context;

  return (
    <DialogInParent aria-describedby={descriptionId} open={dialogOpen}>
      <Card variant='outlined' sx={{ maxWidth: 250 }}>
        <CardContent>
          <Stack alignItems='center' spacing={1}>
            <InfoOutlinedIcon color='primary' sx={{ fontSize: 60 }} />
            <Typography
              id={descriptionId}
              fontSize='1rem'
              fontWeight={variables.weightSemiBold}
              textAlign='center'
            >
              Data has {error === 'not_loaded' ? 'not loaded' : 'expired'},
              please refresh your page or click refresh now
            </Typography>
            <Button
              variant='contained'
              startIcon={syncing ? <SyncProgress /> : <SyncIcon />}
              onClick={refresh}
              disabled={syncing}
            >
              Refresh now
            </Button>
            <Typography fontSize='0.75rem'>
              Last Refresh: {formatTime(lastUpdatedDate, 'date')}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </DialogInParent>
  );
}
