import { NullComponent, SyncButton, useScopedState } from '@insidedesk/tuxedo';
import { Button, DialogActions, Popover, PopoverProps } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';

export default function SnoozePopover({
  open,
  onClose,
  submitting,
  onSubmit,
  ...rest
}: {
  open: boolean;
  onClose: () => void;
  submitting: boolean;
  onSubmit: (value: Dayjs) => void;
} & Omit<PopoverProps, 'open' | 'onClose' | 'onSubmit'>) {
  const [value, setValue] = useScopedState<Dayjs | null>(null, open);

  const handleChange = useCallback(
    (newValue: Dayjs | null) => {
      setValue(newValue);
    },
    [setValue],
  );

  const handleOk = useCallback(() => {
    if (value !== null) {
      onSubmit(value);
    }
  }, [onSubmit, value]);

  return (
    <Popover
      data-testid='snooze-popover'
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      role='dialog'
      {...rest}
    >
      <StaticDatePicker
        value={value}
        onChange={handleChange}
        slots={{ actionBar: NullComponent }}
        minDate={dayjs().add(1, 'day')}
        maxDate={dayjs().add(1, 'month')}
      />
      {/* Default action bar doesn't seem to have a reliable way to access its
            events, so make our own instead. */}
      <DialogActions sx={{ px: 2, pb: 2, pt: 0 }}>
        <Button variant='text' autoFocus onClick={onClose} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <SyncButton
          variant='contained'
          onClick={handleOk}
          disabled={value === null}
          syncing={submitting}
        >
          {submitting ? 'Snoozing' : 'Snooze'}
        </SyncButton>
      </DialogActions>
    </Popover>
  );
}
