import { Tooltip } from '@mui/material';
import {
  AlternativeBenefitIcon,
  DowngradedIcon,
  PaidOkIcon,
  PaidOverIcon,
  PaidUnderIcon,
  PaidZeroIcon,
} from '../../../assets';
import { ClaimProcedure, ClaimResponseEntryAdjudication } from '../../../types';

export default function ServiceLineIcon({
  procedure,
  adjudication,
}: {
  procedure?: ClaimProcedure;
  adjudication?: ClaimResponseEntryAdjudication;
}) {
  if (!procedure) {
    return (
      <Tooltip arrow title='Alternate Benefit Applied'>
        <AlternativeBenefitIcon />
      </Tooltip>
    );
  }
  if (!adjudication) {
    return (
      <Tooltip arrow title='Submitted - Downgraded'>
        <DowngradedIcon />
      </Tooltip>
    );
  }
  if ((adjudication.benefit ?? 0) === 0) {
    return (
      <Tooltip arrow title='Paid Zero'>
        <PaidZeroIcon />
      </Tooltip>
    );
  }
  if (adjudication.benefit === procedure.estimate) {
    return (
      <Tooltip arrow title='Paid Ok'>
        <PaidOkIcon />
      </Tooltip>
    );
  }
  if ((adjudication.benefit ?? 0) <= (procedure.estimate ?? 0)) {
    return (
      <Tooltip arrow title='Paid Under Expected'>
        <PaidUnderIcon />
      </Tooltip>
    );
  }
  if ((adjudication.benefit ?? 0) >= (procedure.estimate ?? 0)) {
    return (
      <Tooltip arrow title='Paid Over Expected'>
        <PaidOverIcon />
      </Tooltip>
    );
  }
  return null;
}
