import { OutlinedIconButton, SingleSelect } from '@insidedesk/tuxedo';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { FormContainer } from 'react-hook-form-mui';
import {
  claimListFiltersAtom,
  claimListOptionsAtom,
  claimSearchAtom,
  hasDrawerFiltersAtom,
  resetDrawerFiltersAtom,
} from '../../state';
import { ClaimFilterOption, ClaimListDrawerFilters } from '../../types';
import DoSRangePicker from './DoSRangePicker';

type QuickSelectProps = {
  name: {
    [key in keyof ClaimListDrawerFilters]: ClaimListDrawerFilters[key] extends ClaimFilterOption[]
      ? key
      : never;
  }[keyof ClaimListDrawerFilters];
  label: string;
};
const ALL: ClaimFilterOption = { id: 'all', label: 'All' };

function QuickSelect(props: QuickSelectProps) {
  const { name, label } = props;
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);
  const options = useAtomValue(claimListOptionsAtom);

  const optionsWithAll = [ALL].concat(options[name]);
  const valueOrAll = filters[name][0] ?? ALL;
  const handleChange = useCallback(
    (e: unknown, value: ClaimFilterOption) => {
      if (value.id === ALL.id) {
        updateFilters({ [name]: [] });
      } else {
        updateFilters({ [name]: [value] });
      }
    },
    [name, updateFilters],
  );

  return (
    <SingleSelect
      name={name}
      label={label}
      options={optionsWithAll}
      value={valueOrAll}
      onChange={handleChange}
      disableClearable
      size='small'
    />
  );
}

function Search() {
  const [searchValue, setSearchValue] = useAtom(claimSearchAtom);

  return (
    <FormContainer
      onSuccess={() =>
        setSearchValue({ value: searchValue, setQueryParam: true })
      }
    >
      <TextField
        size='small'
        id='search'
        label='Search'
        variant='outlined'
        value={searchValue}
        onFocus={(event) => event.target.select()}
        onChange={(event) => setSearchValue({ value: event.target.value })}
        sx={{ width: 'max(100%, 200px)' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton
                aria-labelledby='search'
                onClick={() => {
                  setSearchValue({ value: searchValue, setQueryParam: true });
                }}
              >
                <SearchOutlinedIcon color='primary' />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchValue && (
            <InputAdornment position='end'>
              <IconButton
                aria-label='clear search'
                onClick={() => {
                  setSearchValue({ value: '', setQueryParam: true });
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormContainer>
  );
}

export default function QuickFilters(props: { onOpenDrawer: () => void }) {
  const { onOpenDrawer } = props;
  const theme = useTheme();
  const hasDrawerFilters = useAtomValue(hasDrawerFiltersAtom);
  const reset = useSetAtom(resetDrawerFiltersAtom);
  const filters = useAtomValue(claimListFiltersAtom);

  const quickFilters: QuickSelectProps[] = [
    { name: 'portal', label: 'Portal' },
    { name: 'responseStatus', label: 'Response' },
    { name: 'submittedAmount', label: 'Submitted Amount' },
    { name: 'arAge', label: 'Ar Age' },
  ];

  const hasAdvancedFilters = quickFilters
    .map(({ name }) => name)
    .some((name) => filters[name].length > 1);

  const clearAdvancedFiltersElement = (
    <FormControlLabel
      control={
        <IconButton onClick={reset}>
          <CancelOutlinedIcon />
        </IconButton>
      }
      label='Advanced Filters Applied'
      labelPlacement='start'
      slotProps={{ typography: { variant: 'h6', color: 'primary' } }}
      sx={{ gap: 1, m: 0 }}
    />
  );

  return (
    <Grid container spacing={2} alignItems='center' columns={8}>
      <Grid item xs>
        <Search />
      </Grid>
      <Grid
        item
        xs
        sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}
      >
        <DoSRangePicker range='dosStart' />
        <DoSRangePicker range='dosEnd' />
      </Grid>
      {!hasAdvancedFilters ? (
        quickFilters.map(({ name, label }) => (
          <Grid key={name} item xs={1}>
            <QuickSelect name={name} label={label} />
          </Grid>
        ))
      ) : (
        <Grid item xs='auto'>
          {clearAdvancedFiltersElement}
        </Grid>
      )}
      <Grid item xs='auto'>
        <OutlinedIconButton
          aria-label='Advanced Filters'
          sx={{
            color: hasDrawerFilters ? 'white' : 'primary.main',
            bgcolor: hasDrawerFilters ? 'primary.main' : 'unset',
            transition: theme.transitions.create('all'),
          }}
          onClick={onOpenDrawer}
        >
          <FilterAltIcon />
        </OutlinedIconButton>
      </Grid>
    </Grid>
  );
}
