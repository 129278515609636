import { Button, styled } from '@mui/material';

const LinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'underline',
})<{ underline: boolean }>(({ underline }) => ({
  '& .MuiTouchRipple-root': { display: 'none' },
  background: 'none !important',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  padding: '0',
  textAlign: 'unset',
  verticalAlign: 'unset',
  textDecoration: `${underline ? 'underline' : 'none'} !important`,
  transition: 'unset',
}));

export default LinkButton;
