import {
  CopyButton,
  KeyboardShortcutBarrier,
  MutationErrorAlert,
  PHI,
  ProductName,
  ProductPreviewContext,
  ProductPreviewProvider,
  SyncButton,
  useFlags,
  useHasPermission,
  UserAvatar,
  useScopedState,
  useSnackbar,
  useUserProfile,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Button,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  SvgIcon,
  Tab,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, ReactNode, useContext, useMemo, useState } from 'react';
import {
  FormContainer,
  TextareaAutosizeElement,
  useForm,
} from 'react-hook-form-mui';
import { useInterval } from 'usehooks-ts';
import {
  Artifact,
  CardWithExpiredPopup,
  DialogToolbar,
  RoundedTabList,
} from '..';
import { useCalls, useClaimNotes, usePostClaimNote } from '../../../hooks';
import {
  ClaimCall,
  ClaimNote,
  ClaimResponseEntry,
  ClaimResponseEntryArtifact,
} from '../../../types';
import {
  formatTime,
  getCallGradient,
  getCallIcon,
  getCallStatusText,
} from '../../../utils';
import LinkButton from '../../LinkButton';

const TAG_ORDER = [
  'call',
  /* XXX: Reversal allows determining order with indexOf while properly ordering
   * non-specified tags last.
   */
].reverse();

type TaggedArtifact = { tag: string } & ClaimResponseEntryArtifact;
type EntryWithTaggedArtifacts = {
  artifacts: [TaggedArtifact, ...ClaimResponseEntryArtifact[]];
} & ClaimResponseEntry;

function isTaggedArtifact(
  artifact: ClaimResponseEntryArtifact,
): artifact is TaggedArtifact {
  return typeof artifact.tag === 'string';
}

function parseBody(body: string | null) {
  return {
    body: body?.replace(/\(edited\)$/, '') ?? null,
    edited: body?.endsWith('(edited)') ?? false,
  };
}

function formatBody(body: string | null, edited: boolean) {
  const suffix = edited ? '(edited)' : null;
  return `${body ?? ''}${suffix ?? ''}`;
}

function useExpireText(expireDate: Dayjs) {
  const getCurrent = () => {
    const expiresInMinutes = expireDate.diff(dayjs(), 'minute');
    return expiresInMinutes.toLocaleString(undefined, {
      style: 'unit',
      unit: 'minute',
      unitDisplay: 'short',
    });
  };

  const [expireText, setExpireText] = useState<string | null>(getCurrent());

  useInterval(() => {
    setExpireText(getCurrent());
  }, 1000);

  return expireText;
}

function NoteBody({ note, onEdit }: { note: ClaimNote; onEdit: () => void }) {
  const user = useUserProfile();
  const { hasPermission } = useHasPermission();
  const { preview } = useContext(ProductPreviewContext);

  const expireDate = dayjs.utc(note.created).add(1, 'hour');
  const expireText = useExpireText(expireDate);
  const isEditable =
    expireDate.isValid() &&
    dayjs() <= expireDate &&
    note.user?.id === user.id &&
    hasPermission('write:claim-notes');

  const { body, edited } = parseBody(note.body);

  if (body === null) return null;

  return (
    <Stack spacing={2}>
      <Typography
        variant='body2'
        whiteSpace='pre-wrap'
        sx={{ wordBreak: 'break-word' }}
      >
        <PHI>{body}</PHI>
        <CopyButton label='Note' text={body} disabled={preview} />
      </Typography>
      {edited && (
        <Typography variant='caption' mt={0}>
          Edited
        </Typography>
      )}
      {isEditable && (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Button
            startIcon={<EditIcon />}
            variant='outlined'
            onClick={() => onEdit()}
          >
            Edit
          </Button>
          <Typography variant='caption'>Editable - {expireText}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

function EditingNoteBody({
  claimId,
  note,
  onClose,
}: {
  claimId: string;
  note: ClaimNote;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const mutation = usePostClaimNote(claimId);
  const handleSuccess = (values: { body: string }) => {
    mutation.mutate(
      { note_id: note.id, body: formatBody(values.body, true) },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: 'success',
            message: 'Note Updated',
            icon: <SummarizeOutlinedIcon />,
          });
          onClose();
        },
      },
    );
  };

  const { body } = parseBody(note.body);
  const formContext = useForm({ defaultValues: { body: body ?? '' } });
  const { formState } = formContext;

  return (
    <FormContainer
      onSuccess={handleSuccess}
      formContext={formContext}
      FormProps={{ style: { width: '100%' } }}
    >
      <Stack spacing={1}>
        <PHI>
          <KeyboardShortcutBarrier>
            <TextareaAutosizeElement
              required
              aria-label='Body'
              name='body'
              fullWidth
            />
          </KeyboardShortcutBarrier>
        </PHI>
        <MutationErrorAlert
          message='Error editing a note, please try again'
          mutation={mutation}
        />
        <Stack direction='row' width='100%' spacing={1}>
          <Button onClick={() => onClose()} sx={{ flexGrow: 1 }}>
            Cancel
          </Button>
          <SyncButton
            type='submit'
            variant='contained'
            sx={{ flexGrow: 3 }}
            disabled={!formState.isDirty}
            syncing={mutation.isLoading}
          >
            Update Note
          </SyncButton>
        </Stack>
      </Stack>
    </FormContainer>
  );
}

function Item(props: {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  indentBody?: ReactNode;
  fullBody?: ReactNode;
}) {
  const { icon, title, subtitle, indentBody, fullBody } = props;

  return (
    <ListItem alignItems='flex-start' sx={{ flexWrap: 'wrap', p: 2 }}>
      <ListItemAvatar sx={{ minWidth: 'fit-content', pr: 2 }}>
        {icon}
      </ListItemAvatar>
      <div>
        <Typography variant='body1'>{title}</Typography>
        <Typography variant='caption' display='block' mt='-0.3em' gutterBottom>
          {subtitle}
        </Typography>
        {indentBody}
      </div>
      {fullBody}
    </ListItem>
  );
}

function NoteItem({ claimId, note }: { claimId: string; note: ClaimNote }) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Item
      icon={
        <PHI>
          <UserAvatar user={note.user} />
        </PHI>
      }
      title={<PHI>{note.user?.name}</PHI>}
      subtitle={formatTime(note.created)}
      indentBody={
        !isEditing && <NoteBody note={note} onEdit={() => setIsEditing(true)} />
      }
      fullBody={
        isEditing && (
          <EditingNoteBody
            claimId={claimId}
            note={note}
            onClose={() => setIsEditing(false)}
          />
        )
      }
    />
  );
}

function CallArtifactDialog({
  open,
  onClose,
  artifacts,
}: {
  open: boolean;
  onClose: () => void;
  artifacts: [TaggedArtifact, ...TaggedArtifact[]];
}) {
  const [selectedTab, setSelectedTab] = useScopedState<string>(
    artifacts[0].tag,
    open,
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogToolbar
        onClose={onClose}
        fontSize='22px'
        fontWeight={variables.weightRegular}
        text={['Call', ' Result']}
        flexGrow={1}
      />
      <DialogContent>
        <TabContext value={selectedTab}>
          <RoundedTabList
            variant='standard'
            centered
            onChange={(e, tab) => setSelectedTab(tab)}
          >
            {artifacts.map((artifact) => (
              <Tab
                key={artifact.id}
                label={
                  <Stack sx={{ fontWeight: variables.weightMedium }}>
                    <Typography color='inherit' fontSize='0.625rem'>
                      <ProductName product='dial' forceNewBranding />
                    </Typography>
                    <span>{artifact.tag}</span>
                  </Stack>
                }
                value={artifact.tag}
              />
            ))}
          </RoundedTabList>
          {artifacts.map((artifact) => (
            <TabPanel
              key={artifact.id}
              value={artifact.tag}
              sx={{ paddingTop: 0 }}
            >
              <Artifact artifact={artifact} />
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}

function CallItem(props: { call: ClaimCall }) {
  const { call } = props;
  const statusText = getCallStatusText(call.status);
  const gradient = getCallGradient(call.status);
  const Icon = getCallIcon(call.status);
  const matches = call.best_response?.matches ?? [];
  const entries = matches
    .filter(
      (entry_): entry_ is EntryWithTaggedArtifacts =>
        entry_.artifacts.filter(isTaggedArtifact).length > 0,
    )
    .sort((a, b) => dayjs(b.processed).diff(dayjs(a.processed)));
  const entry = entries[0];

  const artifacts = useMemo(
    () =>
      entry?.artifacts
        .filter(isTaggedArtifact)
        .sort(({ tag: tagA }, { tag: tagB }) => {
          const tagAIndex = TAG_ORDER.indexOf(tagA?.toLowerCase());
          const tagBIndex = TAG_ORDER.indexOf(tagB?.toLowerCase());
          return tagBIndex - tagAIndex;
        }) ?? [],
    [entry?.artifacts],
  );

  const [callArtifactDialogOpen, setCallArtifactDialogOpen] = useState(false);
  const icon = (
    <SvgIcon
      sx={{
        background: `linear-gradient(${gradient[0]}, ${gradient[1]})`,
        padding: 0.75,
        borderRadius: '10px',
        width: 30,
        height: 30,
        color: 'white',
      }}
    >
      <Icon />
    </SvgIcon>
  );
  return (
    <Item
      icon={icon}
      title={
        <>
          <ProductName product='dial' /> {statusText}
        </>
      }
      subtitle={`${formatTime(call.created)} - ${call.user?.name ?? 'Unknown'}`}
      indentBody={
        artifacts.length > 0 &&
        artifacts[0] !== undefined && (
          <ProductPreviewProvider preview={false}>
            <LinkButton
              color='inherit'
              onClick={() => setCallArtifactDialogOpen(true)}
              underline
            >
              View Results
            </LinkButton>
            <CallArtifactDialog
              open={callArtifactDialogOpen}
              onClose={() => setCallArtifactDialogOpen(false)}
              artifacts={[artifacts[0], ...artifacts.slice(1)]}
            />
          </ProductPreviewProvider>
        )
      }
    />
  );
}

export default function ClaimNotesCard({ claimId }: { claimId: string }) {
  const flags = useFlags();
  const claimNotesQuery = useClaimNotes(claimId);
  const claimCallsQuery = useCalls(
    { claimIds: [claimId], all: true },
    { enabled: Boolean(flags.superDialNotes) },
  );

  const isSuccess =
    claimNotesQuery.isSuccess &&
    (!flags.superDialNotes || claimCallsQuery.isSuccess);
  const items = [
    ...(claimNotesQuery.data ?? []).map(
      (note) => ({ ...note, type: 'note' }) as const,
    ),
    ...(claimCallsQuery.data ?? []).map(
      (call) => ({ ...call, type: 'call' }) as const,
    ),
  ].sort((a, b) => dayjs.utc(b.created).diff(dayjs.utc(a.created)));

  return (
    <CardWithExpiredPopup
      variant='flat'
      color='accent-secondary'
      queries={[
        claimNotesQuery,
        ...(flags.superDialNotes ? [claimCallsQuery] : []),
      ]}
      data-testid='notes-card'
    >
      <CardContent sx={{ px: 0 }}>
        <List>
          {items?.map((item, index) => (
            <Fragment key={`${item.type}-${item.id}`}>
              {item.type === 'note' ? (
                <NoteItem claimId={claimId} note={item} />
              ) : (
                <CallItem call={item} />
              )}
              {index + 1 !== items?.length && <Divider />}
            </Fragment>
          ))}
        </List>
        {isSuccess && items?.length === 0 && (
          <Typography variant='body2' textAlign='center'>
            There are no notes to display
          </Typography>
        )}
      </CardContent>
    </CardWithExpiredPopup>
  );
}
