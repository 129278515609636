import { useSnackbar } from '@insidedesk/tuxedo';
import { useCallback } from 'react';
import { ClaimUIState } from '../types';
import { usePatchUnread } from './useMutateClaimUiState';

export default function useUnreadBehavior(
  claimId: string,
  uiState: ClaimUIState | null,
) {
  const { enqueueSnackbar } = useSnackbar();
  const patchUnreadMutation = usePatchUnread(claimId);
  const isUnread = Boolean(uiState?.unread);

  const handleClick = useCallback(() => {
    patchUnreadMutation.mutate(!isUnread, {
      onError: () => {
        enqueueSnackbar({
          variant: 'error',
          message: `We were unable to mark ${
            isUnread ? 'read' : 'unread'
          }, please try again`,
        });
      },
    });
  }, [enqueueSnackbar, isUnread, patchUnreadMutation]);

  return { isUnread, toggle: handleClick };
}
