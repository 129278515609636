import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { ClaimDetails } from '../types';

export default function useClaimUIState(claimId: string) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<ClaimDetails>(`claims/${claimId}`);
  return useQuery<ClaimDetails, unknown, ClaimDetails['ui_state']>(
    ['claims', claimId, 'details'],
    queryFn,
    {
      select: (data) => data.ui_state,
      enabled: hasPermission('read:claims'),
    },
  );
}
