import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  Card,
  CardContent,
  CardHeader,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { ComponentType, ReactNode } from 'react';

interface SoftPopupProps {
  Icon: ComponentType<SvgIconProps>;
  message: ReactNode;
}
/** A small popup-like card which has an icon and a brief message */
export default function SoftPopup(props: SoftPopupProps) {
  const { Icon, message } = props;
  return (
    <Card variant='outlined' sx={{ maxWidth: '250px', minHeight: '220px' }}>
      <CardHeader
        title={<Icon sx={{ fontSize: 'inherit' }} />}
        titleTypographyProps={{
          fontSize: '48px',
          color: 'primary.main',
          textAlign: 'center',
          sx: { width: '100%' },
        }}
      />
      <CardContent>
        <Typography
          variant='body1'
          fontWeight={variables.weightSemiBold}
          textAlign='center'
        >
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
}
