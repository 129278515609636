import { useSnackbar } from '@insidedesk/tuxedo';
import { Button, CardActions, CardContent, Stack } from '@mui/material';
import { BaseSyntheticEvent, SyntheticEvent, useCallback } from 'react';
import { CheckboxElement, FormContainer, useForm } from 'react-hook-form-mui';
import { ClaimCategory, ClaimDetails } from '../../../types';

export default function ClaimCategoryEditor({
  claimDetails,
  onClose,
  onSubmit,
}: {
  claimDetails: ClaimDetails;
  onClose: (e: SyntheticEvent<Element>) => void;
  onSubmit: (categories: ClaimCategory[], e?: BaseSyntheticEvent) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
    commercial: !claimDetails.categories.includes('medicaid'),
    medicaid: claimDetails.categories.includes('medicaid'),
    ortho: claimDetails.categories.includes('ortho'),
  };
  type FormFields = typeof defaultValues;

  const handleSuccess = useCallback(
    (formValues: FormFields, e?: BaseSyntheticEvent) => {
      const newCategories = new Set(claimDetails.categories);
      (['medicaid', 'ortho'] satisfies ClaimCategory[]).forEach((category) => {
        if (formValues[category]) {
          newCategories.add(category);
        } else {
          newCategories.delete(category);
        }
      });

      onSubmit(Array.from(newCategories), e);
    },
    [claimDetails.categories, onSubmit],
  );

  const resolver = useCallback(
    (values: FormFields) => {
      if (!values.commercial && !values.medicaid) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'Please select at least one of Commercial or Medicaid to continue',
        });
        return {
          values: {},
          errors: {
            commercial: { type: 'validate' },
            medicaid: { type: 'validate' },
          },
        };
      }
      return { values, errors: {} };
    },
    [enqueueSnackbar],
  );

  const formContext = useForm({ defaultValues, resolver });

  return (
    <FormContainer formContext={formContext} onSuccess={handleSuccess}>
      <CardContent data-testid='category-editor'>
        <Stack>
          <CheckboxElement
            name='commercial'
            label='COMMERCIAL'
            labelProps={{ slotProps: { typography: { color: 'primary' } } }}
            onChange={(e, checked) => {
              if (checked) {
                formContext.reset((fields) => ({
                  ...fields,
                  medicaid: !checked,
                }));
              }
            }}
          />
          <CheckboxElement
            name='medicaid'
            label='MEDICAID'
            labelProps={{ slotProps: { typography: { color: 'primary' } } }}
            onChange={(e, checked) => {
              if (checked) {
                formContext.reset((fields) => ({
                  ...fields,
                  commercial: !checked,
                }));
              }
            }}
          />
          <CheckboxElement
            name='ortho'
            label='ORTHO'
            labelProps={{ slotProps: { typography: { color: 'primary' } } }}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant='contained' type='submit'>
          Update
        </Button>
      </CardActions>
    </FormContainer>
  );
}
