import { ClaimProcedure, ClaimResponseEntryAdjudication } from '../../../types';

export interface CombinedService {
  procedure?: ClaimProcedure;
  adjudication?: ClaimResponseEntryAdjudication;
}

export function combineServices(
  procedures: readonly ClaimProcedure[],
  adjudications: readonly ClaimResponseEntryAdjudication[],
) {
  const remainingProcedures = Array.from(procedures);
  const combinedServices = [] as CombinedService[];
  adjudications.forEach((adjudication) => {
    const index = remainingProcedures.findIndex(
      (procedure) =>
        procedure.service_date === adjudication.service_date &&
        procedure.cdt.trim() === adjudication.cdt?.trim(),
    );
    const matchingProcedure =
      index >= 0 ? remainingProcedures.splice(index, 1)[0] : undefined;
    combinedServices.push({ adjudication, procedure: matchingProcedure });
  });
  remainingProcedures.forEach((procedure) =>
    combinedServices.push({ procedure }),
  );
  return combinedServices;
}
