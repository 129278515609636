import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DenialReason } from '../types';

export default function useClientDenialReasons(): UseQueryResult<
  DenialReason[]
> {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<DenialReason[]>('client/denial-reasons');
  return useQuery(['denialReasons'], queryFn, {
    select: (queryData) => {
      const data = Array.from(queryData);
      data.sort((a, b) => {
        if (a.client_grouping === b.client_grouping) {
          return a.reason.localeCompare(b.reason);
        }
        return a.client_grouping.localeCompare(b.client_grouping);
      });
      return data;
    },
    enabled: hasPermission('read:rcm'),
  });
}
