import { SyncProgress } from '@insidedesk/tuxedo';
import { Stack } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

export default function SyncIndicator() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const visibility = isFetching || isMutating ? 'visible' : 'hidden';
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={1}
      visibility={visibility}
      sx={{ color: 'primary.main' }}
    >
      <div>Syncing</div>
      {isFetching || isMutating ? (
        <SyncProgress />
      ) : (
        <div style={{ width: '1em', height: '1em' }} />
      )}
    </Stack>
  );
}
