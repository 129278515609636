import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { ClaimDetails } from '../types';

export default function useClaimDetails(claimId: string) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<ClaimDetails>(`claims/${claimId}`);
  const { data: claimDetails, ...rest } = useQuery(
    ['claims', claimId, 'details'],
    queryFn,
    { enabled: hasPermission('read:claims') },
  );
  return { claimDetails, ...rest };
}
