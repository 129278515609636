import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { ClaimCall } from '../types';

export default function useCalls(
  params: { claimIds: string[]; all?: boolean } = { claimIds: [] },
  options: QueryObserverOptions<ClaimCall[]> = {},
) {
  const queryParams = new URLSearchParams();
  params.claimIds.forEach((id) => {
    queryParams.append('claim_id', id.toString());
  });
  if (params.all !== undefined) {
    queryParams.set('all', params.all.toString());
  }

  const queryFn = useClientAPIQueryFn<ClaimCall[]>(
    `calls/?${queryParams.toString()}`,
  );

  return useQuery({
    queryFn,
    queryKey: ['calls', queryParams.toString()],
    ...options,
  });
}
