import {
  ProductPreviewContext,
  useHasPermission,
  useSnackbar,
} from '@insidedesk/tuxedo';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import MarkMailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Button, Stack } from '@mui/material';
import { useCallback, useContext } from 'react';
import { FeedbackDialog, SnoozePopover } from '..';
import useFeedbackBehavior from '../../hooks/useFeedbackBehavior';
import useSnoozeBehavior from '../../hooks/useSnoozeBehavior';
import useUnreadBehavior from '../../hooks/useUnreadBehavior';
import { ClaimDetails } from '../../types';

export default function ClaimActions({
  claimDetails,
}: {
  claimDetails: ClaimDetails;
}) {
  const { hasPermission } = useHasPermission();
  const { enqueueSnackbar } = useSnackbar();

  const { preview } = useContext(ProductPreviewContext);

  const handleShare = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    enqueueSnackbar({ variant: 'share', message: 'Claim URL copied' });
  }, [enqueueSnackbar]);

  const feedbackBehavior = useFeedbackBehavior(claimDetails.id.toString());
  const snoozeBehavior = useSnoozeBehavior(
    claimDetails.id.toString(),
    claimDetails.ui_state,
  );
  const unreadBehavior = useUnreadBehavior(
    claimDetails.id.toString(),
    claimDetails.ui_state,
  );

  return (
    <Stack direction='row' spacing={0.5} data-testid='claim-actions'>
      {hasPermission('write:claim-feedback') && (
        <Button
          data-testid='feedback-button'
          variant='tab'
          startIcon={<FeedbackOutlinedIcon color='primary' />}
          onClick={feedbackBehavior.toggle}
          disabled={preview}
        >
          Feedback
        </Button>
      )}
      {hasPermission('write:claim-snooze') && (
        <Button
          data-testid='snooze-button'
          variant='tab'
          startIcon={<AccessTimeIcon color='primary' />}
          onClick={(e) => snoozeBehavior.toggle({ anchorEl: e.currentTarget })}
          disabled={preview}
        >
          {snoozeBehavior.isSnoozed ? 'Snoozed' : 'Snooze'}
        </Button>
      )}
      {hasPermission('write:claim-unread') && (
        <Button
          data-testid='unread-button'
          variant='tab'
          startIcon={<MarkMailReadOutlinedIcon color='primary' />}
          onClick={unreadBehavior.toggle}
          disabled={preview}
        >
          {unreadBehavior.isUnread ? 'Unread' : 'Read'}
        </Button>
      )}
      <Button
        data-testid='share-button'
        variant='tab'
        startIcon={<ShareOutlinedIcon color='primary' />}
        onClick={handleShare}
        disabled={preview}
      >
        Share
      </Button>
      <FeedbackDialog {...feedbackBehavior.dialogProps} />
      <SnoozePopover {...snoozeBehavior.popoverProps} />
    </Stack>
  );
}
