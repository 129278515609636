import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import { Link } from 'react-router-dom';
import { ErrorPage } from '../components';

export default function UnknownError() {
  return (
    <ErrorPage
      Icon={MoodBadOutlinedIcon}
      headerText={['E', 'RR', 'OR']}
      content='Sorry, we encountered an error. The team has been notified and are looking into the issue.'
      action={
        <Link to='/claims'>Click here to go back to the claim list page.</Link>
      }
    />
  );
}
