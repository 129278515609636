import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { FacilityFilterOption } from '../types';

export default function useFacilityFilterOptions(
  options?: UseQueryOptions<FacilityFilterOption[]>,
) {
  const queryFn = useClientAPIQueryFn<FacilityFilterOption[]>(
    'claims/facility-filter-options',
  );
  return useQuery({ queryKey: ['facilityFilterOptions'], queryFn, ...options });
}
