import { SyncStringStorage } from 'jotai/vanilla/utils/atomWithStorage';

const cascadingStorage: SyncStringStorage = {
  getItem(key) {
    return sessionStorage.getItem(key) ?? localStorage.getItem(key);
  },
  setItem(key, value) {
    sessionStorage.setItem(key, value);
    localStorage.setItem(key, value);
  },
  removeItem(key) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  },
};
export default cascadingStorage;
