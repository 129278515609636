import { MutationBuilder, useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PostUnmatchResponse, PostUnmatchVariables } from '../types';

export default function usePostUnmatchClaimResponseEntry(
  claimId: number,
  entryId?: number,
) {
  const queryClient = useQueryClient();
  const mutationFn = useClientAPIMutationFn<
    PostUnmatchResponse,
    PostUnmatchVariables
  >(`claim-response-entry/${entryId}/unmatch`, { method: 'POST' });

  const options = new MutationBuilder(queryClient, { mutationFn })
    .invalidate(['claims', String(claimId), 'details'])
    .invalidate(['claimList'])
    .configure();

  return useMutation(options);
}
