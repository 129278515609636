import { formatSortDirection, useFlags } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useId } from 'react';
import {
  DEFAULT_SORT,
  DEFAULT_SORT_DIRECTION,
  SORT_ATTRIBUTE_LABELS,
  SORT_OPTION_FLAG_MAP,
} from '../../constants';
import { resetSortAtom, sortsAtom } from '../../state';
import { DUMMY_FLAG, Sort, SortAttribute, SortDirection } from '../../types';
import { otherSortDirection } from '../../utils';

const sortAtom = atom(
  (get) => get(sortsAtom)[0] ?? DEFAULT_SORT,
  (get, set, sort: Sort) => set(sortsAtom, [sort]),
);

const sortAttributeAtom = atom(
  (get) => get(sortAtom).attribute,
  (get, set, attribute: SortAttribute) => {
    const direction = DEFAULT_SORT_DIRECTION[attribute];
    set(sortAtom, { attribute, direction });
  },
);
const sortDirectionAtom = atom(
  (get) => get(sortAtom).direction,
  (get, set) => {
    const { attribute, direction: oldDirection } = get(sortAtom);
    const direction = otherSortDirection(oldDirection);
    set(sortAtom, { attribute, direction });
  },
);

const TAB_HEIGHT = '42px';

type SortTabProps = {
  value: SortAttribute;
  label: string;
};
function SortTab(props: SortTabProps) {
  const { value, label, ...rest } = props;
  const theme = useTheme();
  const [attribute, setAttribute] = useAtom(sortAttributeAtom);
  const [direction, toggleDirection] = useAtom(sortDirectionAtom);

  const Icon =
    direction === SortDirection.Descending
      ? KeyboardArrowDownIcon
      : KeyboardArrowUpIcon;

  const handleClick = () => {
    if (value !== attribute) {
      setAttribute(value);
    } else {
      toggleDirection();
    }
  };

  return (
    <Tab
      label={label}
      icon={
        <Icon
          visibility={attribute === value ? 'visible' : 'hidden'}
          aria-hidden={attribute !== value}
          aria-label={formatSortDirection(direction)}
        />
      }
      iconPosition='start'
      value={value}
      onClick={handleClick}
      sx={{
        pl: 0,
        pr: 1,
        minHeight: TAB_HEIGHT,
        fontSize: '0.875rem',
        color: theme.palette.text.primary,
      }}
      {...rest}
    />
  );
}

function DynamicSort({ labelId }: { labelId: string }) {
  const sortAttribute = useAtomValue(sortAttributeAtom);
  const flags = useFlags();
  const resetSort = useSetAtom(resetSortAtom);

  const enabledAttributes = (
    [
      'service_date',
      'amout_expected',
      'submitted_date',
      'patient',
      'insurer',
      'last_worked',
    ] satisfies SortAttribute[]
  ).filter((value) => {
    if (value in SORT_OPTION_FLAG_MAP) {
      const flagKey = SORT_OPTION_FLAG_MAP[value];
      return flags[flagKey ?? DUMMY_FLAG];
    }
    return true;
  });

  /* XXX: We have to reset the sort here to ensure that in the case of an
   * option getting turned off the selected sort is successfully returned to
   * the default option.
   */
  useEffect(() => {
    if (
      sortAttribute in SORT_OPTION_FLAG_MAP &&
      !flags[SORT_OPTION_FLAG_MAP[sortAttribute] ?? DUMMY_FLAG]
    ) {
      resetSort();
    }
  }, [flags, resetSort, sortAttribute]);

  return (
    <Tabs
      aria-labelledby={labelId}
      value={sortAttribute}
      textColor='secondary'
      indicatorColor='secondary'
      sx={{ minHeight: TAB_HEIGHT, height: TAB_HEIGHT }}
    >
      {enabledAttributes.map((attribute) => (
        <SortTab
          key={attribute}
          label={SORT_ATTRIBUTE_LABELS[attribute]}
          value={attribute}
        />
      ))}
    </Tabs>
  );
}

export default function Sorting() {
  const labelId = useId();
  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      minHeight={TAB_HEIGHT}
    >
      <Typography
        id={labelId}
        fontSize='0.875rem'
        fontWeight={variables.weightMedium}
      >
        Sort Order:
      </Typography>
      <DynamicSort labelId={labelId} />
    </Stack>
  );
}
