import { FullStory } from '@fullstory/browser';
import { useSnackbar } from '@insidedesk/tuxedo';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useCallback, useState } from 'react';
import { usePostFeedback } from '.';
import { FeedbackFormValues } from '../components';

export default function useFeedbackBehavior(claimId: string) {
  const { enqueueSnackbar } = useSnackbar();
  const feedbackMutation = usePostFeedback(claimId);
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSubmit = useCallback(
    (values: FeedbackFormValues) => {
      feedbackMutation.mutate(
        {
          accurate: false,
          body: `${values.subject}: ${
            values.body
          }\n\nFullStory Session: ${FullStory('getSession', {
            format: 'url.now',
          })}`,
        },
        {
          onError: () => {
            enqueueSnackbar({
              variant: 'error',
              message: 'Error submitting your feedback, please try again',
            });
          },
          onSuccess: () => {
            setOpen(false);
            enqueueSnackbar({
              variant: 'success',
              message: 'Feedback Sent',
              icon: <FeedbackOutlinedIcon />,
            });
          },
        },
      );
    },
    [enqueueSnackbar, feedbackMutation],
  );

  return {
    toggle: handleToggle,
    dialogProps: {
      open,
      submitting: feedbackMutation.isLoading,
      onClose: () => setOpen(false),
      onSubmit: handleSubmit,
    },
  };
}
