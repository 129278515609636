/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import { ExcludeFullStory, PHI } from '@insidedesk/tuxedo';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Dialog, DialogContent, Link, Stack } from '@mui/material';
import { useState } from 'react';
import { DialogToolbar } from '..';
import { useCachePresignedUrl } from '../../../hooks';
import { ClaimResponseEntryArtifact } from '../../../types';

function PdfArtifact({
  url,
  artifact,
}: {
  url: URL;
  artifact: ClaimResponseEntryArtifact;
}) {
  return (
    <ExcludeFullStory>
      <div style={{ width: '100%', height: '80vh' }}>
        <PHI>
          <object
            data={url.toString()}
            type='application/pdf'
            width='100%'
            height='100%'
          >
            <iframe
              title={`artifact-${artifact.id}`}
              src={url.toString()}
              width='100%'
              height='100%'
              style={{ border: 'none' }}
            >
              <p>Your browser does not support PDFs.</p>
              <Link href={url.toString()}>Download the PDF</Link>
            </iframe>
          </object>
        </PHI>
      </div>
    </ExcludeFullStory>
  );
}

function ImageArtifact({
  url,
  artifact,
}: {
  url: URL;
  artifact: ClaimResponseEntryArtifact;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    // min-height prevents jump to top of artifact space when loading image
    <ExcludeFullStory>
      <Stack minHeight='80vh' alignContent='center' flexWrap='wrap'>
        <PHI>
          <img
            alt={artifact.tag ?? 'unknown'}
            src={url.toString()}
            onClick={() => setIsOpen(true)}
            style={{ maxWidth: '100%' }}
          />
        </PHI>
      </Stack>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth='xl'>
        <DialogToolbar
          onClose={() => setIsOpen(false)}
          icon={<DescriptionOutlinedIcon />}
          text={[artifact.tag ?? 'unknown', ' image']}
          variant='body1'
        />
        <DialogContent>
          <PHI>
            <img
              alt={artifact.tag ?? 'unknown'}
              src={url.toString()}
              width='100%'
            />
          </PHI>
        </DialogContent>
      </Dialog>
    </ExcludeFullStory>
  );
}

export default function Artifact({
  artifact,
}: {
  artifact: ClaimResponseEntryArtifact;
}) {
  const url = new URL(artifact.location ?? '');
  const cachedUrl = useCachePresignedUrl(
    `artifact-${artifact.id}-${artifact.tag}`,
    url,
  );

  if (cachedUrl.pathname.endsWith('.pdf')) {
    return <PdfArtifact url={cachedUrl} artifact={artifact} />;
  }
  return <ImageArtifact url={cachedUrl} artifact={artifact} />;
}
