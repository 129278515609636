import { useKeyboardShortcut, useUserProfile } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DETAILS_KEYBINDINGS } from '../../constants';
import {
  useFetchNextNavList,
  useFetchPrevNavList,
  useHydrateClaimListOptions,
  useNavList,
} from '../../hooks';
import { pageAtom, rowsPerPageAtom } from '../../state';

function useOverallIndex() {
  const { state } = useLocation();
  if (state === null) return null;
  const { overallIndex } = state;
  return (overallIndex as number | undefined) ?? null;
}

function useNavigateToClaim() {
  const user = useUserProfile();
  const navigate = useNavigate();

  return useCallback(
    (claimId: number, overallIndex: number) =>
      navigate(`/claim/${user.client.name}/${claimId}`, {
        state: { overallIndex },
      }),
    [navigate, user.client.name],
  );
}

export function useClaimNavigation() {
  const overallIndex = useOverallIndex();
  const [page, setPage] = useAtom(pageAtom);
  const rowsPerPage = useAtomValue(rowsPerPageAtom);

  useHydrateClaimListOptions({
    enabled: overallIndex !== null,
    refetchOnMount: false,
  });
  const query = useNavList(overallIndex !== null);
  const navigateToClaim = useNavigateToClaim();

  const fetchPrevNavList = useFetchPrevNavList();
  const fetchNextNavList = useFetchNextNavList();
  const [isFetchingNewPage, setIsFetchingNewPage] = useState(false);

  const claims = query.data?.page ?? [];
  const pageIndex = Math.max(
    0,
    Math.min(claims.length, (overallIndex ?? 0) % rowsPerPage),
  );
  const nextClaim = claims[pageIndex + 1];
  const prevClaim = claims[pageIndex - 1];

  const next = useCallback(async () => {
    if (nextClaim !== undefined) {
      navigateToClaim(nextClaim.id, (overallIndex ?? 0) + 1);
    } else {
      setIsFetchingNewPage(true);
      const result = await fetchNextNavList().finally(() =>
        setIsFetchingNewPage(false),
      );
      const nextClaims = result.page ?? [];
      const claim = nextClaims[0];
      if (claim !== undefined) {
        setPage(page + 1);
        navigateToClaim(claim.id, (overallIndex ?? 0) + 1);
      }
    }
  }, [
    fetchNextNavList,
    navigateToClaim,
    nextClaim,
    overallIndex,
    page,
    setPage,
  ]);

  const prev = useCallback(async () => {
    if (prevClaim !== undefined) {
      navigateToClaim(prevClaim.id, (overallIndex ?? 0) - 1);
    } else {
      setIsFetchingNewPage(true);
      const result = await fetchPrevNavList().finally(() =>
        setIsFetchingNewPage(false),
      );
      const prevClaims = result.page ?? [];
      const claim = prevClaims[prevClaims.length - 1];
      if (claim !== undefined) {
        setPage(page - 1);
        navigateToClaim(claim.id, (overallIndex ?? 0) - 1);
      }
    }
  }, [
    fetchPrevNavList,
    navigateToClaim,
    overallIndex,
    page,
    prevClaim,
    setPage,
  ]);

  const totalItems = query.data?.totalItems;
  const hasPrev = (overallIndex ?? 0) > 0;
  const hasNext = (overallIndex ?? 0) < (totalItems ?? 0) - 1;

  if (overallIndex == null) return null;
  return {
    isLoading: query.isFetching || isFetchingNewPage,
    isError: query.isError,
    next,
    prev,
    overallIndex,
    totalItems,
    hasPrev,
    hasNext,
  };
}

export default function ClaimNavigationCard() {
  const context = useClaimNavigation();

  useKeyboardShortcut<keyof typeof DETAILS_KEYBINDINGS>('forward', () => {
    if (context === null) return;
    if (context.isLoading) return;
    if (!context.hasNext) return;
    context.next();
  });
  useKeyboardShortcut<keyof typeof DETAILS_KEYBINDINGS>('back', () => {
    if (context === null) return;
    if (context.isLoading) return;
    if (!context.hasPrev) return;
    context.prev();
  });

  if (context === null) return null;
  const {
    hasPrev,
    hasNext,
    prev,
    next,
    overallIndex,
    totalItems,
    isLoading,
    isError,
  } = context;
  if (isError) return null;

  return (
    <Card variant='outlined'>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        spacing={1}
        padding={2}
      >
        {hasPrev && (
          <IconButton aria-label='Previous' onClick={prev} disabled={isLoading}>
            <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        )}
        <Typography
          data-testid='navigation-label'
          fontSize='0.875rem'
          fontWeight={variables.weightRegular}
        >
          Claims:{' '}
          <Typography
            component='span'
            color='primary.main'
            fontSize='0.875rem'
            fontWeight={variables.weightSemiBold}
          >
            {overallIndex + 1}
          </Typography>{' '}
          of {totalItems}
        </Typography>
        {hasNext && (
          <IconButton aria-label='Next' onClick={next} disabled={isLoading}>
            <ArrowCircleRightOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        )}
      </Stack>
    </Card>
  );
}
