import { Link } from '@mui/material';
import { ReactNode } from 'react';

export default function PhoneNumber({
  number,
  fallback,
}: {
  number: string | null;
  fallback?: ReactNode;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (number === null) return <>{fallback}</>;
  return (
    <Link onClick={(e) => e.stopPropagation()} href={`tel:${number}`}>
      {number}
    </Link>
  );
}
