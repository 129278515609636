import { Permission, useHasPermission } from '@insidedesk/tuxedo';
import { UnauthorizedError } from '../error';

export default function PermissionGuard({
  require,
  children,
}: {
  require: Permission;
  children: JSX.Element;
}) {
  const { hasPermission } = useHasPermission();
  if (hasPermission(require)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return children;
  }
  throw new UnauthorizedError(`You need ${require} to see this content`);
}
