import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { CheckNumber } from '../types';

export default function useCheckNumbers(claimId: string) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<CheckNumber[]>(
    `payments/check-numbers/${claimId}`,
  );
  return useQuery(['checkNumbers', claimId], queryFn, {
    enabled: hasPermission('read:claims'),
  });
}
