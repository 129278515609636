import { useFlags } from '@insidedesk/tuxedo';
import { ButtonProps, IconButtonProps, Tooltip } from '@mui/material';
import { ElementType, Fragment } from 'react';
import type { ClaimCall } from '../../types';
import {
  getCallButtonText,
  getCallGradient,
  getCallIcon,
  getCallTooltipText,
} from '../../utils';
import DialButton from '../DialButton';

type CallButtonProps<
  IconOnly extends boolean = false,
  Component extends ElementType = 'button',
> = {
  call: ClaimCall | null;
  iconOnly?: IconOnly;
  showTooltip?: boolean;
} & (IconOnly extends true
  ? IconButtonProps<Component>
  : ButtonProps<Component>);

export default function CallButton<
  IconOnly extends boolean = false,
  Component extends ElementType = 'button',
>(props: CallButtonProps<IconOnly, Component>) {
  const {
    call,
    iconOnly = false,
    disabled,
    showTooltip = false,
    ...rest
  } = props;
  const flags = useFlags();

  const Icon = getCallIcon(call?.status ?? null);
  const gradient = getCallGradient(call?.status ?? null);
  const buttonText = getCallButtonText(call?.status ?? null);
  const TooltipWrapper = showTooltip ? Tooltip : Fragment;

  return (
    <TooltipWrapper
      title={getCallTooltipText(call?.status ?? null)}
      placement={flags.tooltipPlacementTop ? 'top' : 'bottom'}
      arrow
    >
      <div style={{ pointerEvents: 'auto', borderRadius: '50%' }}>
        <DialButton
          iconOnly={iconOnly}
          buttonText={buttonText}
          gradient={gradient}
          Icon={Icon}
          disabled={disabled || call?.status === 'PENDING'}
          forceEnabledStyle={!disabled && call?.status === 'PENDING'}
          {...rest}
        />
      </div>
    </TooltipWrapper>
  );
}
