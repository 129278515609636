import {
  HTTPError,
  useClientAPIQueryFn,
  useHasPermission,
} from '@insidedesk/tuxedo';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ClaimDetails, RedactedCredential } from '../types';

export default function useClaimCredentials(
  claimId: ClaimDetails['id'],
  options: UseQueryOptions<
    unknown,
    HTTPError,
    RedactedCredential[],
    string[]
  > = {},
) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<RedactedCredential[], QueryKey>(
    `claims/${claimId}/credentials`,
  );

  return useQuery<unknown, HTTPError, RedactedCredential[], string[]>(
    ['claims', claimId.toString(), 'credentials'],
    queryFn,
    {
      ...options,
      enabled:
        hasPermission('read:claims') &&
        hasPermission('read:credentials') &&
        (options?.enabled ?? true),
    },
  );
}
