import { LabelledCell, LabelledCellDef } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  formControlLabelClasses,
  Radio,
  Stack,
  styled,
} from '@mui/material';
import { useCallback } from 'react';
import { ClaimResponseEntry } from '../../types';
import { formatCurrency, formatDate } from '../../utils';

const MultiSelectLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: variables.borderLight,
  margin: 0,
  [`& .${formControlLabelClasses.label}`]: {
    flexGrow: 1,
  },
}));

export default function MultiSelectCard({
  entries,
  selectedEntry,
  onSelectedEntryChange,
}: {
  entries: readonly ClaimResponseEntry[];
  selectedEntry: ClaimResponseEntry | undefined;
  onSelectedEntryChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    entry: ClaimResponseEntry,
  ) => void;
}) {
  const getCells = useCallback(
    (entry: ClaimResponseEntry): LabelledCellDef[] => [
      { label: 'Claim ID', value: entry.insurer_reference },
      { label: 'Processed Date', value: formatDate(entry.processed) },
      { label: 'Allowable', value: formatCurrency(entry.fee_allowed) },
      { label: 'Benefit Paid', value: formatCurrency(entry.fee_paid) },
    ],
    [],
  );

  if (entries.length <= 1) return null;

  return (
    <Card variant='outlined' color='accent-secondary'>
      <CardHeader title='Multiclaim' />
      <CardContent>
        <Stack direction='column' spacing={2}>
          {entries.map((entry) => (
            <MultiSelectLabel
              key={entry.id}
              control={
                <Radio
                  inputProps={{
                    'aria-label': entry.insurer_reference ?? undefined,
                  }}
                  checked={entry === selectedEntry}
                  onChange={(event, checked) => {
                    if (checked) {
                      onSelectedEntryChange(event, entry);
                    }
                  }}
                />
              }
              label={
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  flexWrap='wrap'
                  gap={1}
                >
                  {getCells(entry).map((cell) => (
                    <LabelledCell
                      key={
                        typeof cell.label === 'string'
                          ? cell.label
                          : cell.description
                      }
                      {...cell}
                    />
                  ))}
                  <div /> {/* force spacing to the right */}
                </Stack>
              }
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
