import { ReactNode } from 'react';
import { ExternalLink } from '.';

export default function PortalLink({
  href,
  fallback,
}: {
  href: string | null;
  fallback?: ReactNode;
}) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (href === null) return <>{fallback}</>;
  let url;
  try {
    url = new URL(href);
  } catch (err) {
    if ((err as Error).name !== 'TypeError') throw err;
  }
  const hostname = url?.hostname ?? href;

  return (
    <ExternalLink onClick={(e) => e.stopPropagation()} href={href}>
      {hostname}
    </ExternalLink>
  );
}
