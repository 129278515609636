/*
 * XXX Fractional grid columns are used due to https://github.com/mui/material-ui/issues/36335
 * As the potential fix https://github.com/mui/material-ui/pull/36401 applies
 * to Grid2, this file uses Grid2 instead of Grid.
 */
import { LabelledCell } from '@insidedesk/tuxedo';
import { Icon, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactNode } from 'react';
import {
  ClaimProcedure,
  ClaimResponseEntry,
  ClaimResponseEntryAdjudication,
} from '../../../types';
import { formatCurrency, formatDate } from '../../../utils';
import ServiceLineIcon from './ServiceLineIcon';
import './ServiceTable.scss';
import { combineServices } from './util';

function LineGrid({
  className,
  prelude,
  pms,
  insurer,
  showInsurer,
  ...rest
}: {
  className?: string;
  prelude: ReactNode;
  pms: ReactNode;
  insurer: ReactNode;
  showInsurer: boolean;
  rest?: JSX.IntrinsicAttributes;
}) {
  if (showInsurer) {
    return (
      <Grid container className={className} {...rest}>
        <Grid container xs={(12 * 2) / 7} className='box box-left'>
          {prelude}
        </Grid>
        <Grid container xs={(12 * 2) / 7} className='box box-center'>
          {pms}
        </Grid>
        <Grid container xs={(12 * 3) / 7} className='box box-right'>
          {insurer}
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container className={className} {...rest}>
      <Grid container xs={6} className='box box-left'>
        {prelude}
      </Grid>
      <Grid container xs={6} className='box box-right'>
        {pms}
      </Grid>
    </Grid>
  );
}

function ServiceHeadersLine({ showInsurer }: { showInsurer: boolean }) {
  return (
    <LineGrid
      className='service-header'
      prelude={null}
      pms={<span className='header-cell'>PMS</span>}
      insurer={<span className='header-cell'>Insurer</span>}
      showInsurer={showInsurer}
    />
  );
}

function ServiceLine({
  procedure,
  adjudication,
  showIcon,
  showInsurer,
}: {
  procedure?: ClaimProcedure;
  adjudication?: ClaimResponseEntryAdjudication;
  showIcon?: boolean;
  showInsurer: boolean;
}) {
  function format(value: number | null | undefined) {
    // Return '-' if value is undefined, this means parent doesn't exist.
    // Return 'Unavailable' If it is null, this means that the value could not be retrieved.
    if (value === undefined) return '-';
    if (value === null) return 'Unavailable';
    return formatCurrency(value ?? 0, { hideZeros: false });
  }
  const pmsCells = [
    {
      label: 'Submitted',
      value: format(procedure?.submitted),
    },
    {
      label: 'Expected',
      value: format(procedure?.estimate),
      hideInPreview: true,
    },
  ];
  const insurerCells = [
    {
      label: 'Submitted',
      value: format(adjudication?.submitted),
      hideInPreview: true,
    },
    {
      label: 'Allowable',
      value: format(adjudication?.eligible),
      hideInPreview: true,
    },
    {
      label: 'Benefit',
      value: format(adjudication?.benefit),
      hideInPreview: true,
    },
  ];

  const prelude = (
    <>
      <Grid xs={8}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {showIcon ? (
            <ServiceLineIcon
              procedure={procedure}
              adjudication={adjudication}
            />
          ) : (
            <Icon />
          )}
          <LabelledCell
            label='Service Date'
            value={formatDate(
              procedure?.service_date ?? adjudication?.service_date ?? null,
            )}
            hideInPreview
          />
        </Stack>
      </Grid>
      <Grid xs>
        <LabelledCell
          label='Procedure'
          value={procedure?.cdt ?? adjudication?.cdt ?? 'N/A'}
          hideInPreview
        />
      </Grid>
    </>
  );

  const pms = (
    <>
      {pmsCells.map((cell) => (
        <Grid key={cell.label} xs>
          <LabelledCell {...cell} />
        </Grid>
      ))}
    </>
  );

  const insurer = (
    <>
      {insurerCells.map((cell) => (
        <Grid key={cell.label} xs>
          <LabelledCell {...cell} />
        </Grid>
      ))}
    </>
  );

  return (
    <LineGrid
      className='service-line'
      data-testid='service-line'
      prelude={prelude}
      pms={pms}
      insurer={insurer}
      showInsurer={showInsurer}
    />
  );
}

function ServiceTotalsLine({
  procedures,
  adjudications,
  showInsurer,
}: {
  procedures: readonly ClaimProcedure[];
  adjudications: readonly ClaimResponseEntryAdjudication[];
  showInsurer: boolean;
}) {
  const pmsCells = [
    {
      label: 'Total Submitted',
      value: formatCurrency(
        procedures
          .map((procedure) => procedure.submitted ?? 0)
          .reduce((a, b) => a + b, 0),
        { hideZeros: false },
      ),
    },
    {
      label: 'Total Expected',
      value: formatCurrency(
        procedures
          .map((procedure) => procedure.estimate ?? 0)
          .reduce((a, b) => a + b, 0),
        { hideZeros: false },
      ),
      hideInPreview: true,
    },
  ];
  const insurerCells = [
    {
      label: 'Total Submitted',
      value: formatCurrency(
        adjudications
          .map((adjudication) => adjudication.submitted ?? 0)
          .reduce((a, b) => a + b, 0),
        { hideZeros: false },
      ),
      hideInPreview: true,
    },
    {
      label: 'Total Allowable',
      value: formatCurrency(
        adjudications
          .map((adjudication) => adjudication.eligible ?? 0)
          .reduce((a, b) => a + b, 0),
        { hideZeros: false },
      ),
      hideInPreview: true,
    },
    {
      label: 'Total Benefit',
      value: formatCurrency(
        adjudications
          .map((adjudication) => adjudication.benefit ?? 0)
          .reduce((a, b) => a + b, 0),
        { hideZeros: false },
      ),
      hideInPreview: true,
    },
  ];

  return (
    <LineGrid
      className='service-totals'
      prelude={
        <>
          <Grid xs={8} />
          <Grid xs className='header-cell'>
            Totals
          </Grid>
        </>
      }
      pms={pmsCells.map((cell) => (
        <Grid key={cell.label} xs>
          <LabelledCell {...cell} />
        </Grid>
      ))}
      insurer={insurerCells.map((cell) => (
        <Grid key={cell.label} xs>
          <LabelledCell {...cell} />
        </Grid>
      ))}
      showInsurer={showInsurer}
    />
  );
}

export default function ServiceTable({
  entry,
  procedures,
}: {
  entry?: ClaimResponseEntry;
  procedures: readonly ClaimProcedure[];
}) {
  const { adjudications = [], artifacts = [] } = entry ?? {};
  const combinedServices = combineServices(procedures, adjudications);

  const hasEOB = artifacts.map((artifact) => artifact.tag).includes('eob');

  const showInsurer = Boolean(entry?.match);

  return (
    <Stack direction='column' spacing={2} className='service-root'>
      <ServiceHeadersLine showInsurer={showInsurer} />
      {combinedServices.map(({ procedure, adjudication }) => (
        <ServiceLine
          key={`${procedure?.id}, ${adjudication?.id}`}
          procedure={procedure}
          adjudication={adjudication}
          showIcon={hasEOB}
          showInsurer={showInsurer}
        />
      ))}
      <ServiceTotalsLine
        procedures={procedures}
        adjudications={adjudications}
        showInsurer={showInsurer}
      />
    </Stack>
  );
}
