import { FlagSet } from '@insidedesk/tuxedo';
import dayjs from 'dayjs';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import _ from 'lodash';
import {
  CATEGORY_OPTIONS,
  CLAIMS_TAB_OPTIONS,
  DEFAULT_CATEGORY,
  DEFAULT_CLAIMS_TAB,
  DEFAULT_CLAIM_FILTERS,
  DEFAULT_DRAWER_FILTERS,
  DEFAULT_SORT,
} from '../constants';
import { NoFacilitiesError } from '../error';
import {
  CategoryFilterOption,
  ClaimFilterValues,
  ClaimListDrawerFilters,
  ClaimListFilterValues,
  ClaimsTabFilterOption,
  FacilityFilterOption,
  Sort,
} from '../types';
import cascadingStorage from '../utils/cascadingStorage';

const facilityStorageAtom = atomWithStorage<FacilityFilterOption | null>(
  'facility',
  null,
  createJSONStorage(() => cascadingStorage),
);
const facilityOptionsAtom = atom([] as FacilityFilterOption[]);
export const facilityPendingAtom = atom(true);

const hydrateFacilityOptionsAtom = atom(
  null,
  (get, set, update: FacilityFilterOption[]) => {
    const matchingOption =
      update.find((option) => option.id === get(facilityStorageAtom)?.id) ??
      update[0];
    if (matchingOption === undefined) {
      throw new NoFacilitiesError('No facility options');
    }
    set(facilityOptionsAtom, update);
    set(facilityStorageAtom, matchingOption);
    set(facilityPendingAtom, false);
  },
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const categoryStorageAtom = atomWithStorage<CategoryFilterOption>(
  'category',
  DEFAULT_CATEGORY,
  createJSONStorage(() => cascadingStorage),
);
const categoryAtom = atom(
  (get) =>
    CATEGORY_OPTIONS.find(
      (option) => option.id === get(categoryStorageAtom).id,
    ) ?? DEFAULT_CATEGORY,
  (get, set, update: CategoryFilterOption) => {
    set(categoryStorageAtom, update);
  },
);

const claimsTabStorageAtom = atomWithStorage<ClaimsTabFilterOption>(
  'claimsTab',
  DEFAULT_CLAIMS_TAB,
  createJSONStorage(() => cascadingStorage),
);
const claimsTabAtom = atom(
  (get) =>
    CLAIMS_TAB_OPTIONS.find((option) => option === get(claimsTabStorageAtom)) ??
    DEFAULT_CLAIMS_TAB,
  (get, set, update: ClaimsTabFilterOption) => {
    set(claimsTabStorageAtom, update);
  },
);

const searchInputAtom = atom<string | undefined>(undefined);
const searchQueryAtom = atom<string | undefined>(undefined);

export const claimSearchAtom = atom(
  (get) => {
    const input = get(searchInputAtom);
    if (input !== undefined) {
      return input;
    }
    return get(searchQueryAtom);
  },
  (
    get,
    set,
    update: { value: string | undefined; setQueryParam?: boolean },
  ) => {
    set(searchInputAtom, update.value);

    if (update.setQueryParam) {
      set(claimListFiltersAtom, { searchQuery: update.value });
    }
  },
);

const resetSearchAtom = atom(null, (get, set) => {
  set(searchInputAtom, '');
  set(searchQueryAtom, '');
});

const dosRangeStorageAtom = atomWithStorage<{
  dosStart: string | null;
  dosEnd: string | null;
}>(
  'dosRange',
  { dosStart: null, dosEnd: null },
  createJSONStorage(() => cascadingStorage),
);

const paidSubscriberStorageAtom = atomWithStorage<boolean>(
  'paidSubscriber',
  false,
  createJSONStorage(() => cascadingStorage),
);
const updatedStorageAtom = atomWithStorage<boolean>(
  'updated',
  false,
  createJSONStorage(() => cascadingStorage),
);
const postedNotClosedStorageAtom = atomWithStorage<boolean>(
  'postedNotClosed',
  false,
  createJSONStorage(() => cascadingStorage),
);

const claimFiltersStorageAtom = atomWithStorage<ClaimFilterValues>(
  'claimFilters',
  DEFAULT_CLAIM_FILTERS,
  createJSONStorage(() => cascadingStorage),
);
const claimFilterOptionsAtom = atom(DEFAULT_CLAIM_FILTERS);
const claimFiltersPendingAtom = atom(true);

const hydrateClaimFilterOptionsAtom = atom(
  null,
  (get, set, update: ClaimFilterValues & { flags: FlagSet }) => {
    const filters = get(claimFiltersStorageAtom);
    const { flags, ...update_ } = update;

    const newFilters: ClaimFilterValues = Object.fromEntries(
      Object.entries(update_).map(([key, options]) => {
        const values = options.filter((option) =>
          (filters[key as unknown as keyof typeof filters] ?? []).find(
            (value) => value.id === option.id,
          ),
        );
        return [key, values] as const;
      }),
    );
    if (!flags?.persistClaimListFilters) {
      set(claimFiltersStorageAtom, newFilters);
    }
    set(claimFilterOptionsAtom, update_);
    set(claimFiltersPendingAtom, false);
  },
);

const drawerFiltersAtom = atom(
  (get): ClaimListDrawerFilters => ({
    ...get(claimFiltersStorageAtom),
    dosStart: get(dosRangeStorageAtom).dosStart
      ? dayjs(get(dosRangeStorageAtom).dosStart)
      : null,
    dosEnd: get(dosRangeStorageAtom).dosEnd
      ? dayjs(get(dosRangeStorageAtom).dosEnd)
      : null,
    paidSubscriber: get(paidSubscriberStorageAtom),
    updated: get(updatedStorageAtom),
    postedNotClosed: get(postedNotClosedStorageAtom),
  }),
  (get, set, update: Partial<ClaimListDrawerFilters>) => {
    if (update.paidSubscriber !== undefined) {
      set(paidSubscriberStorageAtom, update.paidSubscriber);
    }
    if (update.updated !== undefined) {
      set(updatedStorageAtom, update.updated);
    }
    if (update.postedNotClosed !== undefined) {
      set(postedNotClosedStorageAtom, update.postedNotClosed);
    }
    const claimFilters = _.omit(
      update,
      'paidSubscriber',
      'updated',
      'postedNotClosed',
    );
    set(claimFiltersStorageAtom, (prev) => ({ ...prev, ...claimFilters }));
  },
);
export const resetDrawerFiltersAtom = atom(null, (get, set) =>
  // It's safe to not mark as pending as we know defaults are valid
  set(drawerFiltersAtom, DEFAULT_DRAWER_FILTERS),
);

export const hasDrawerFiltersAtom = atom(
  (get) => !_.isEqual(DEFAULT_DRAWER_FILTERS, get(drawerFiltersAtom)),
);

const hasSufficientDrawerFiltersForAllTabAtom = atom((get) => {
  const { dosStart, dosEnd, ...rest } = get(drawerFiltersAtom);
  const {
    dosStart: defaultDosStart,
    dosEnd: defaultDosEnd,
    ...defaultRest
  } = DEFAULT_DRAWER_FILTERS;

  /**
   * This check is to enforce both a start and end date of service for filtering
   * on the 'All' tab if no other filters are applied.
   */
  if (_.isEqual(rest, defaultRest)) return dosStart !== null && dosEnd !== null;
  return !_.isEqual(rest, defaultRest);
});

export const requiresFiltersAtom = atom((get) => {
  const hasSufficientFilters = get(hasSufficientDrawerFiltersForAllTabAtom);
  return (
    get(claimsTabAtom) === 'all' &&
    !(hasSufficientFilters || Boolean(get(searchQueryAtom)))
  );
});

export type Pagination = {
  page: number;
  rowsPerPage: number;
  totalItems: number | null;
};
const paginationStorageAtom = atomWithStorage<Pagination>(
  'pagination',
  { page: 0, rowsPerPage: 25, totalItems: null },
  createJSONStorage(() => cascadingStorage),
);
export const pageAtom = atom(
  (get) => get(paginationStorageAtom).page,
  (get, set, page: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page }));
  },
);
export const rowsPerPageAtom = atom(
  (get) => get(paginationStorageAtom).rowsPerPage,
  (get, set, rowsPerPage: number) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, page: 0, rowsPerPage }));
  },
);
export const totalItemsAtom = atom(
  (get) => get(paginationStorageAtom).totalItems,
  (get, set, totalItems: number | null) => {
    set(paginationStorageAtom, (prev) => ({ ...prev, totalItems }));
  },
);

const sortsStorageAtom = atomWithStorage(
  'sorts',
  [DEFAULT_SORT],
  createJSONStorage(() => cascadingStorage),
);
export const sortsAtom = atom(
  (get) => get(sortsStorageAtom),
  (get, set, sorts: Sort[]) => {
    set(sortsStorageAtom, sorts);
    set(pageAtom, 0);
  },
);

export const resetSortAtom = atom(null, (get, set) => {
  set(sortsStorageAtom, [DEFAULT_SORT]);
});

export const claimListFiltersAtom = atom(
  (get) =>
    ({
      facility: get(facilityStorageAtom),
      category: get(categoryAtom),
      claimsTab: get(claimsTabAtom),
      searchQuery: get(searchQueryAtom),
      ...get(drawerFiltersAtom),
    }) as ClaimListFilterValues,
  (
    get,
    set,
    update: Partial<ClaimListFilterValues> &
      (
        | { facility: FacilityFilterOption; flags: FlagSet }
        | { facility?: undefined; flags?: undefined }
      ),
  ) => {
    if (update.facility) {
      set(facilityStorageAtom, update.facility);
    }
    if (update.category) {
      set(categoryAtom, update.category);
    }
    if (update.claimsTab) {
      set(claimsTabAtom, update.claimsTab);
    }
    if ('searchQuery' in update) {
      set(searchQueryAtom, update.searchQuery);
    }
    if ('dosStart' in update) {
      set(dosRangeStorageAtom, (prev) => ({
        ...prev,
        dosStart: update.dosStart?.toString() ?? null,
      }));
    }
    if ('dosEnd' in update) {
      set(dosRangeStorageAtom, (prev) => ({
        ...prev,
        dosEnd: update.dosEnd?.toString() ?? null,
      }));
    }
    if (update.facility && !update.flags.persistClaimListFilters) {
      set(resetDrawerFiltersAtom);
      set(resetSortAtom);
      set(resetSearchAtom);
    }

    const drawerFilters = _.omit(
      update,
      'facility',
      'category',
      'claimsTab',
      'searchQuery',
      'dosStart',
      'dosEnd',
    );
    set(drawerFiltersAtom, drawerFilters);

    // Updating params resets pagination
    set(pageAtom, 0);
    set(totalItemsAtom, null);
  },
);

export const claimListPendingAtom = atom((get) => ({
  claims:
    get(facilityPendingAtom) ||
    get(claimFiltersPendingAtom) ||
    get(requiresFiltersAtom),
  counts: get(facilityPendingAtom) || get(claimFiltersPendingAtom),
  claimFilterOptions: get(facilityPendingAtom),
}));

export const claimListOptionsAtom = atom(
  (get) => ({
    facility: get(facilityOptionsAtom),
    ...get(claimFilterOptionsAtom),
  }),
  (
    get,
    set,
    update: {
      facility?: FacilityFilterOption[];
    } & (
      | {
          claimFilters: ClaimFilterValues;
          flags: FlagSet;
        }
      | {
          claimFilters?: undefined;
          flags?: undefined;
        }
    ),
  ) => {
    if (update.facility) {
      set(hydrateFacilityOptionsAtom, update.facility);
    }
    if (update.claimFilters) {
      set(hydrateClaimFilterOptionsAtom, {
        ...update.claimFilters,
        flags: update.flags,
      });
    }
  },
);
