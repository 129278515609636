import { TwoToneHeader } from '@insidedesk/tuxedo';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';
import { SyntheticEvent } from 'react';

type TwoToneHeaderProps = Parameters<typeof TwoToneHeader>[0];
interface DialogToolbarProps extends TwoToneHeaderProps {
  onClose: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

export default function DialogToolbar(props: DialogToolbarProps) {
  const { onClose, ...rest } = props;
  return (
    <DialogTitle
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 3,
      }}
    >
      <TwoToneHeader {...rest} flexGrow={1} />
      <IconButton aria-label='Close' color='primary' onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
}
