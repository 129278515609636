/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
import {
  hashQueryKey,
  QueryClient,
  QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';

export function configureOptimisticUpdate<
  TQueryData,
  TData,
  TError,
  TVariables,
  TContext = Record<string, never>,
>(
  queryClient: QueryClient,
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
  updateKey: QueryKey,
  updateQueryData: (data: TQueryData, variables: TVariables) => TQueryData,
): UseMutationOptions<
  TData,
  TError,
  TVariables,
  TContext & Record<string, unknown>
> {
  const contextKey = `prevData-${hashQueryKey(updateKey)}`;
  return {
    ...options,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(updateKey);

      const prevQueriesData = queryClient.getQueriesData<TQueryData>(updateKey);
      prevQueriesData.forEach(([matchingKey, prevData]) => {
        if (prevData !== undefined) {
          const updatedData = updateQueryData(prevData, variables);
          queryClient.setQueryData(matchingKey, updatedData);
        }
      });

      return {
        ...((options.onMutate &&
          (await options.onMutate(variables))) as TContext),
        [contextKey]: prevQueriesData,
      };
    },
    onError: async (error, variables, context) => {
      if (context !== undefined && contextKey in context) {
        const prevQueriesData = context[contextKey] as [
          QueryKey,
          TQueryData | undefined,
        ][];
        prevQueriesData.forEach(([matchingKey, prevData]) => {
          queryClient.setQueryData(matchingKey, prevData);
        });
      }
      if (options.onError)
        await options.onError(error, variables, context as TContext);
    },
    onSettled: async (data, error, variables, context) => {
      queryClient.invalidateQueries(updateKey);
      if (options.onSettled)
        await options.onSettled(data, error, variables, context as TContext);
    },
    onSuccess: async (data, variables, context) => {
      if (options.onSuccess)
        await options.onSuccess(data, variables, context as TContext);
    },
  };
}

export function configureDelayedUpdate<
  TData,
  TError,
  TVariables,
  TContext,
  TQueryData,
>(
  queryClient: QueryClient,
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
  updateKey: QueryKey,
  updateQueryData: (
    queryData: TQueryData,
    data: TData,
    variables: TVariables,
  ) => TQueryData,
): UseMutationOptions<TData, TError, TVariables, TContext> {
  return {
    ...options,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(updateKey);

      if (options.onMutate) {
        return options.onMutate(variables);
      }
      return {} as TContext;
    },
    onError: async (error, variables, context) => {
      if (options.onError)
        await options.onError(error, variables, context as TContext);
    },
    onSettled: async (data, error, variables, context) => {
      queryClient.invalidateQueries(updateKey);
      if (options.onSettled)
        await options.onSettled(data, error, variables, context as TContext);
    },
    onSuccess: async (data, variables, context) => {
      queryClient
        .getQueriesData<TQueryData>(updateKey)
        .forEach(([matchingQueryKey, prevQueryData]) => {
          if (prevQueryData !== undefined) {
            const updatedData = updateQueryData(prevQueryData, data, variables);
            queryClient.setQueryData(matchingQueryKey, updatedData);
          }
        });

      if (options.onSuccess)
        await options.onSuccess(data, variables, context as TContext);
    },
  };
}

export class MutationBuilder<TData, TError, TVariables, TContext> {
  public constructor(
    private queryClient: QueryClient,
    private options: UseMutationOptions<TData, TError, TVariables, TContext>,
  ) {}

  public optimistic<TQueryData>(
    updateKey: QueryKey,
    updateQueryData: (data: TQueryData, variables: TVariables) => TQueryData,
  ) {
    return new MutationBuilder(
      this.queryClient,
      configureOptimisticUpdate(
        this.queryClient,
        this.options,
        updateKey,
        updateQueryData,
      ),
    );
  }

  public delayed<TQueryData>(
    updateKey: QueryKey,
    updateQueryData: (
      queryData: TQueryData,
      data: TData,
      variables: TVariables,
    ) => TQueryData,
  ) {
    return new MutationBuilder(
      this.queryClient,
      configureDelayedUpdate(
        this.queryClient,
        this.options,
        updateKey,
        updateQueryData,
      ),
    );
  }

  public invalidate(queryKey: QueryKey) {
    return new MutationBuilder(this.queryClient, {
      ...this.options,
      onSuccess: async (data, variables, context) => {
        await this.queryClient.invalidateQueries(queryKey);
        if (this.options.onSuccess)
          await this.options.onSuccess(data, variables, context);
      },
    });
  }

  public configure() {
    return this.options;
  }
}
