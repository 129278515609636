import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { ClaimNote, ClaimNotePosted, Writeable } from '../types';
import { MutationBuilder } from '../utils';

interface MutateClaimNoteVariables {
  note_id?: number;
  body: string;
}

export default function usePostClaimNote(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useClientAPIMutationFn<
    ClaimNotePosted,
    MutateClaimNoteVariables
  >(`claims/${claimId}/notes`, { method: 'POST' });
  const config = new MutationBuilder(queryClient, { mutationFn })
    .delayed(
      ['claims', claimId, 'notes'],
      produce((draft: Writeable<ClaimNote[]>, data: ClaimNotePosted) => {
        const { id: noteId, body, user_id: userId } = data;
        if (noteId !== undefined) {
          const matchingIndex = draft.findIndex((note) => note.id === noteId);
          const matchingNote = draft[matchingIndex];
          if (matchingNote !== undefined) {
            matchingNote.body = body;
          }
        } else {
          // XXX POST response doesn't come with the correct user info. We might
          // be able to guess using the other notes, but if not perform no update.
          const matchingUser = draft
            .map((note) => note.user)
            .find((user) => user?.id === userId);
          if (matchingUser !== undefined) {
            draft.unshift({ ...data, user: matchingUser } satisfies ClaimNote);
          }
        }
      }),
    )
    .invalidate(['claims', claimId, 'history'])
    .configure();
  return useMutation(config);
}
