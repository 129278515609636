import {
  CopyButton,
  MaskFullStory,
  PHI,
  ProductPreviewContext,
  TwoToneHeader,
  useFlags,
} from '@insidedesk/tuxedo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Stack, styled, Typography } from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ClaimDetails } from '../../../types';
import TitleChip from './TitleChip';

const BreadcrumbLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': { color: 'inherit', textDecoration: 'underline' },
});

export type TitleBarProps = { claimDetails: ClaimDetails };
export default function TitleBar({ claimDetails }: TitleBarProps) {
  const flags = useFlags();
  const { preview } = useContext(ProductPreviewContext);
  const { patient, subscriber } = claimDetails;
  const isDependent =
    patient.first_name !== subscriber.first_name ||
    patient.last_name !== subscriber.last_name ||
    (patient.birth_date !== null &&
      subscriber.birth_date !== null &&
      patient.birth_date !== subscriber.birth_date);
  const isPaidSubscriber = claimDetails.categories.includes('paid_subscriber');
  const isPostedNotClosed =
    claimDetails.posted_date !== '0001-01-01' &&
    claimDetails.posted_date !== null &&
    !claimDetails.closed;

  const headerText = [`${patient.last_name}, `, patient.first_name ?? ''];

  return (
    <Stack direction='row'>
      <IconButton aria-label='Back' component={Link} to='/claims'>
        <ArrowBackIosIcon sx={{ fontSize: '40px' }} />
      </IconButton>
      <div>
        <Stack direction='row' alignItems='center' gap={1}>
          {flags.clickTextToCopyValue ? (
            <Typography variant='h1'>
              <CopyButton
                label='Patient Name'
                text={headerText.join('')}
                disabled={preview}
                sx={{ mr: 1 }}
              >
                <MaskFullStory>
                  <PHI component='span'>
                    <TwoToneHeader
                      variant='h1'
                      component='span'
                      text={headerText}
                    />
                  </PHI>
                </MaskFullStory>
              </CopyButton>
            </Typography>
          ) : (
            <>
              <MaskFullStory>
                <PHI>
                  <TwoToneHeader variant='h1' text={headerText} />
                </PHI>
              </MaskFullStory>
              <CopyButton
                label='Patient Name'
                text={headerText.join('')}
                disabled={preview}
                sx={{ ml: -1, mr: 1 }}
              />
            </>
          )}
          {isDependent && <TitleChip color='dependent' label='Dependent' />}
          {isPaidSubscriber && (
            <TitleChip color='paidSubscriber' label='Paid subscriber' />
          )}
          {isPostedNotClosed && (
            <TitleChip color='postedNotClosed' label='Posted not closed' />
          )}
        </Stack>
        <Typography variant='breadcrumb'>
          <BreadcrumbLink to='/claims'>Assist</BreadcrumbLink> /{' '}
          <MaskFullStory>
            <PHI>{headerText.join('')}</PHI>
          </MaskFullStory>
        </Typography>
      </div>
    </Stack>
  );
}
