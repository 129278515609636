import { ProductName, useFlags, useUserProfile } from '@insidedesk/tuxedo';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PREVIEW_CONFIG } from '../../config';
import { cascadingStorage, formatUpgradeRequestSupportUrl } from '../../utils';
import { ExternalLink } from '../ExternalLink';
import './ReportingOnlyBanner.scss';

interface ReportingOnlyBannerProps {
  inPreview?: boolean;
  facilityName?: string;
}
// This changes to getOnInit in jotai v2.6.0
// In 2.7.1 the behavior changes to not need it at all
const reportingOnlyBannerClosedAtom = atomWithStorage<Date | null>(
  'reportingOnlyBannerClosed',
  null,
  createJSONStorage(() => cascadingStorage),
  { unstable_getOnInit: true },
);

export default function ReportingOnlyBanner(props: ReportingOnlyBannerProps) {
  const { inPreview, facilityName } = props;
  const location = useLocation();

  const { state } = location;
  const flags = useFlags();
  const [bannerClosedAt, setBannerClosedAt] = useAtom(
    reportingOnlyBannerClosedAtom,
  );
  const user = useUserProfile();
  const handleClose = useCallback(() => {
    setBannerClosedAt(new Date());
  }, [setBannerClosedAt]);

  const lessThan24HoursSinceClosed =
    bannerClosedAt !== null &&
    dayjs().diff(dayjs(bannerClosedAt), 'hours') < 24;

  const shouldDisplayBanner =
    flags.reportingOnlyBanner &&
    !lessThan24HoursSinceClosed &&
    (inPreview ?? state?.inPreview ?? false);

  if (!shouldDisplayBanner) return null;

  return (
    <div className='reporting-only-banner' role='alert'>
      <div className='reporting-only-banner__text'>
        This Facility is currently subscribed to our Reporting Only Product.{' '}
        <ExternalLink
          color='secondary.contrastText'
          href={PREVIEW_CONFIG.learnMoreUrl}
        >
          To learn more about <ProductName product='assist' /> please click
          here.
        </ExternalLink>{' '}
        If you would like to upgrade this facility&apos;s subscription to
        include claims, claim responses, EOBs, and workflows,{' '}
        <ExternalLink
          color='secondary.contrastText'
          href={
            flags.autoPopulateUpgradeForm
              ? formatUpgradeRequestSupportUrl(user, facilityName)
              : PREVIEW_CONFIG.upgradeUrl
          }
        >
          please click here to contact the support team
        </ExternalLink>
      </div>
      <IconButton
        aria-label='Close'
        id='close-reporting-only-banner'
        onClick={handleClose}
        className='reporting-only-banner__close-button'
      >
        <CloseIcon className='reporting-only-banner__close-icon' />
      </IconButton>
    </div>
  );
}
