import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ClaimHistoryEvent } from '../types';

export default function useClaimHistory(claimId: string) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<ClaimHistoryEvent[]>(
    `claims/${claimId}/history`,
  );
  return useQuery(['claims', claimId, 'history'], queryFn, {
    enabled: hasPermission('read:claims'),
    select: (events) =>
      Array.from(events).sort((a, b) =>
        dayjs.utc(b.timestamp).diff(dayjs.utc(a.timestamp)),
      ),
  });
}
