import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { ClaimNote } from '../types';

export default function useClaimNotes(claimId: string) {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<ClaimNote[]>(`claims/${claimId}/notes`);
  return useQuery(['claims', claimId, 'notes'], queryFn, {
    enabled: hasPermission('read:claims'),
  });
}
