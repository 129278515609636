import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';

export default function useDenialReasonIds(claimId: string) {
  const { hasAllPermissions } = useHasPermission();
  const queryFn = useClientAPIQueryFn<number[]>(
    `claims/${claimId}/denial-reasons`,
  );
  return useQuery(['claims', claimId, 'denialReasons'], queryFn, {
    enabled: hasAllPermissions('read:rcm', 'read:claims'),
  });
}
