export const AUTH0_CONFIG = {
  audience: 'https://client-api.insidedesk.com',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: `insidedesk-${process.env.REACT_APP_AUTH0_TENANT}.us.auth0.com`,
  cookieDomain: `insidedesk-${process.env.REACT_APP_AUTH0_TENANT}.us.auth0.com`,
};

export const NAVBAR_CONFIG = {
  assistUrl: process.env.REACT_APP_ASSIST_PAGE ?? '',
  credentialsUrl: process.env.REACT_APP_CREDENTIALS_PAGE ?? '',
  iqUrl: process.env.REACT_APP_IQ_PAGE ?? '',
  remitUrl: process.env.REACT_APP_REMIT_PAGE ?? '',
  usersUrl: process.env.REACT_APP_USERS_PAGE ?? '',
  helpUrl: process.env.REACT_APP_HELP_URL ?? '',
};

export const API_CONFIG = {
  clientApiUrl: process.env.REACT_APP_CLIENT_API_URL || '',
};

export const VAULT_CONFIG = {
  vaultExtensionId: process.env.REACT_APP_VAULT_EXTENSION_ID || '',
};

export const PENDO_CONFIG = {
  stageId: process.env.REACT_APP_ANALYTICS_STAGE_ID ?? '',
};

export const PREVIEW_CONFIG = {
  learnMoreUrl: process.env.REACT_APP_REPORTING_ONLY_LEARN_MORE_URL ?? '',
  upgradeUrl: process.env.REACT_APP_REPORTING_ONLY_UPGRADE_URL ?? '',
};

export const FULL_STORY_CONFIG = {
  orgId: process.env.REACT_APP_FULL_STORY_ORG_ID,
};
