import { useSnackbar } from '@insidedesk/tuxedo';
import { PopoverProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';
import { ClaimUIState } from '../types';
import { usePatchSnoozed } from './useMutateClaimUiState';

type Anchor = Pick<
  PopoverProps,
  'anchorEl' | 'anchorPosition' | 'anchorOrigin' | 'anchorReference'
>;

export default function useSnoozeBehavior(
  claimId: string,
  uiState: ClaimUIState | null,
) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<Anchor>({});
  const [optimisticMutation, delayedMutation] = usePatchSnoozed(claimId);

  const snoozeUntil = dayjs(uiState?.snooze_until ?? null);
  const isSnoozed = snoozeUntil.isValid() && snoozeUntil.isAfter(dayjs());
  const isSubmitting = delayedMutation.isLoading;

  const handleSubmit = useCallback(
    (value: Dayjs) => {
      delayedMutation.mutate(value, {
        onError: () => {
          enqueueSnackbar({
            variant: 'error',
            message: `We were unable to snooze the claim, please try again`,
          });
        },
        onSuccess: () => {
          setOpen(false);
        },
      });
    },
    [delayedMutation, enqueueSnackbar],
  );

  const handleClear = useCallback(() => {
    optimisticMutation.mutate(null, {
      onError: () => {
        enqueueSnackbar({
          variant: 'error',
          message: `We were unable to wake up the claim, please try again`,
        });
      },
    });
  }, [enqueueSnackbar, optimisticMutation]);

  const handleToggle = useCallback(
    (newAnchor: Anchor = {}) => {
      if (isSnoozed) {
        handleClear();
      } else {
        setOpen(true);
        setAnchor(newAnchor);
      }
    },
    [handleClear, isSnoozed],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    isSnoozed,
    toggle: handleToggle,
    popoverProps: {
      open,
      onClose: handleClose,
      submitting: isSubmitting,
      onSubmit: handleSubmit,
      ...anchor,
    },
  };
}
