import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import { CenterInPage, LoadingSpinner } from '@insidedesk/tuxedo';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AUTH0_CONFIG } from './config';
import './global-setup';
import './index.css';
import reportWebVitals from './reportWebVitals';
import routes from './routes';
import theme from './theme';

const Router = withAuthenticationRequired(
  function Router() {
    return <RouterProvider router={createBrowserRouter(routes)} />;
  },
  {
    onRedirecting: () => (
      <CenterInPage>
        <LoadingSpinner />
      </CenterInPage>
    ),
  },
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      {...AUTH0_CONFIG}
      redirectUri={window.location.origin}
      useRefreshTokens
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </LocalizationProvider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
