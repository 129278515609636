import { SyncProgress, useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Statistics } from '../../types';
import { formatTime } from '../../utils';

export default function FacilityLastUpdated(props: { facilityID: number }) {
  const { facilityID } = props;

  const theme = useTheme();

  const fetchFacilityStats = useClientAPIQueryFn<
    Statistics,
    ['facilities', number, 'statistics']
  >(`facilities/${facilityID}/statistics`);
  const facilityStatsQuery = useQuery({
    queryKey: ['facilities', facilityID, 'statistics'],
    queryFn: fetchFacilityStats,
  });

  if (facilityStatsQuery.isError) {
    return null;
  }
  if (facilityStatsQuery.isLoading) {
    return <SyncProgress />;
  }

  return (
    <Typography
      variant='subtitle2'
      sx={{ textTransform: 'uppercase', color: theme.palette.grey[600] }}
    >
      <span id='facility-last-updated'>Facility Last Updated:</span>{' '}
      <span aria-labelledby='facility-last-updated'>
        {formatTime(facilityStatsQuery.data.last_synced)}
      </span>
    </Typography>
  );
}
