import { Link, LinkProps } from '@mui/material';

export type ExternalLinkProps = Omit<LinkProps, 'href'> & {
  href: string | null;
};
export function ExternalLink(props: ExternalLinkProps) {
  const { href, children, ...rest } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (href === null) return <>{children}</>;
  return (
    <Link target='_blank' rel='noopener noreferrer' href={href} {...rest}>
      {children}
    </Link>
  );
}
