import { SortDirection } from '../types';

/**
 * Returns a proxy which proxies attributes to attributes of `value.current`.
 * Use to change a value without explicitly re-assigning it.
 *
 * If the `current` attribute pattern is analogous to interior mutability, this
 * is analogous to automatic dereferencing of an interior mutable value.
 *
 * Please use sparingly.
 */
// eslint-disable-next-line import/prefer-default-export
export function currentProxy<
  T extends { [k: string | symbol]: unknown },
>(value: { current: T }) {
  return new Proxy(
    {},
    {
      get(_target, prop: keyof T) {
        return value.current[prop];
      },
    },
  ) as unknown as T;
}

export function otherSortDirection(direction: SortDirection) {
  return direction === SortDirection.Ascending
    ? SortDirection.Descending
    : SortDirection.Ascending;
}
