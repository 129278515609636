import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WorkflowState, WorkflowStateDiscriminator } from '../types';

export default function useClientWorkflowStates(
  discriminator: WorkflowStateDiscriminator,
): UseQueryResult<WorkflowState[]> {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<WorkflowState[]>(
    `client/workflow-states?discriminator=${discriminator}`,
  );
  return useQuery<WorkflowState[]>(['workflowStates', discriminator], queryFn, {
    select: (queryData) => {
      const data = Array.from(queryData);
      data.sort((a, b) => a.state.localeCompare(b.state));
      return data;
    },
    enabled: hasPermission('read:rcm'),
  });
}
