import dayjs from 'dayjs';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';

const presignedUrlStoreAtom = atom({} as Record<string, URL>);

const EXPIRE_PADDING = [5, 'minute'] as const;

function getUrlExpireDate(presignedUrl: URL) {
  const expireTimestamp = presignedUrl.searchParams.get('Expires');
  if (expireTimestamp === null) return null;
  return dayjs(parseInt(expireTimestamp, 10) * 1000);
}

/**
 * A presigned url store which automatically expires urls as needed. If a
 * url has no expiry date it is assumed to expire immediately (the idea is
 * you could put a non-presigned url into this store and it would immediately
 * expire rather than breaking anything).
 */
function usePresignedUrlStore() {
  const [store, setStore] = useAtom(presignedUrlStoreAtom);
  const expireKey = useCallback(
    (key: string) => setStore((prev) => _.omit(prev, key)),
    [setStore],
  );
  const getUrl = useCallback(
    (key: string) => {
      const url = store[key];
      if (url === undefined) return null;
      const expireDate = getUrlExpireDate(url);
      if (expireDate === null) return null;
      if (dayjs() >= expireDate.subtract(...EXPIRE_PADDING)) {
        expireKey(key);
        return null;
      }
      return url;
    },
    [expireKey, store],
  );
  const setUrl = useCallback(
    (key: string, url: URL) => {
      if (getUrlExpireDate(url) !== null) {
        setStore((prev) => ({ ...prev, [key]: url }));
      }
    },
    [setStore],
  );

  return { getUrl, setUrl };
}

/**
 * Presigned urls are not stable across api calls, so it is advantageous to
 * use a stable url to prevent visible refreshes. This hook returns the earliest
 * non-expired presigned url for the given key.
 */
export default function useCachePresignedUrl(key: string, url: URL) {
  const { getUrl, setUrl } = usePresignedUrlStore();

  const existingUrl = getUrl(key);
  useEffect(() => {
    if (existingUrl === null) {
      setUrl(key, url);
    }
  }, [existingUrl, key, setUrl, url]);

  return existingUrl ?? url;
}
