// This file is generated by scripts/generate-ui-status-enum.js
// eslint-disable-next-line import/prefer-default-export
export enum UIStatus {
  RECEIVED = 0,
  PROCESSING = 1,
  PMS_DATA_ONLY = 2,
  PAID_AS_EXPECTED = 3,
  PAID_NOT_AS_EXPECTED = 4,
  PAID_ZERO = 5,
  PAID_UNKNOWN = 6,
  NO_CLAIM_YET = 7,
  FACILITY_ISSUE = 8,
  INVALID_DATA = 9,
  LOGIN_ISSUE = 10,
  RETRYING = 11,
  INFO_REQUESTED = 12,
  PAID_OVER_EXPECTED = 13,
  PAID_UNDER_EXPECTED = 14,
  PORTAL_ACTION = 15,
  UPDATE_MFA = 16,
  CLAIM_BATCHED_NOT_SENT = 17,
  CLEARINGHOUSE_VALIDATION_ERROR = 18,
  CLEARINGHOUSE_VALIDATING = 19,
  CLEARINGHOUSE_VALIDATION_ERROR_IDENTIFIED = 20,
  CLEARINGHOUSE_PENDING_SUBMISSION = 21,
  CLEARINGHOUSE_INFO_REQUESTED = 22,
  CLEARINGHOUSE_SUBMITTED = 23,
  CLEARINGHOUSE_RESUBMITTED = 24,
  CLEARINGHOUSE_RECEIVED = 25,
  CLEARINGHOUSE_PROCESSED = 26,
  CLEARINGHOUSE_REJECTED = 27,
  CLEARINGHOUSE_USER_RESOLVED = 28,
  CLAIM_CALL_FAILED = 29,
  CLEARINGHOUSE_SUSPENDED = 30,
  PATIENT_NOT_FOUND = 31,
}
