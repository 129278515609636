import { useAuth0 } from '@auth0/auth0-react';
import { useUserProfile } from '@insidedesk/tuxedo';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import { Button } from '@mui/material';
import { Link, useRouteError } from 'react-router-dom';
import { ErrorPage } from '../components';
import {
  ClaimIdNotFoundError,
  IncorrectUserError,
  NoFacilitiesError,
  UnauthorizedError,
} from '../error';
import { noticeError } from '../utils';
import UnknownError from './UnknownError';

function ClaimIdNotFound() {
  return (
    <ErrorPage
      Icon={WorkOffOutlinedIcon}
      headerText={['Claim ', 'ID ', 'Not Found']}
      headerSize={68}
      action={<Link to='/claims'>Click here to go back to the claim list</Link>}
    />
  );
}

function IncorrectUser() {
  const { email } = useUserProfile();
  const { logout } = useAuth0();
  return (
    <ErrorPage
      Icon={PersonOffOutlinedIcon}
      headerText={['Are you the ', 'right ', 'user']}
      headerSize={48}
      content={
        <>
          You are logged in as {email}.<br />
          Please click the button below to switch users.
        </>
      }
      action={
        <Button
          variant='contained'
          onClick={() => logout({ returnTo: window.location.origin })}
          startIcon={<PersonSearchOutlinedIcon />}
        >
          Switch user
        </Button>
      }
    />
  );
}

function Unauthorized() {
  return (
    <ErrorPage
      Icon={PersonOffOutlinedIcon}
      headerText={['Not ', 'authorized']}
      headerSize={48}
      content={
        <>
          Sorry, you&apos;re not authorized for this feature. If this is a
          mistake please contact your office administrator.
        </>
      }
    />
  );
}

function NoFacilities() {
  return (
    <ErrorPage
      Icon={CorporateFareOutlinedIcon}
      headerText={['E', 'RR', 'OR']}
      content="It looks like you don't have access to any facilities. Please contact customer support to get access to your facilities."
    />
  );
}

export default function ErrorWithinRoot() {
  const error = useRouteError() as Error;

  noticeError(error);

  if (error instanceof ClaimIdNotFoundError) {
    return <ClaimIdNotFound />;
  }
  if (error instanceof IncorrectUserError) {
    return <IncorrectUser />;
  }
  if (error instanceof UnauthorizedError) {
    return <Unauthorized />;
  }
  if (error instanceof NoFacilitiesError) {
    return <NoFacilities />;
  }
  return <UnknownError />;
}
