import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { forwardRef } from 'react';

export const AlternativeBenefitIcon = forwardRef<SVGSVGElement>(
  function AlternativeBenefitIcon(props, ref) {
    return (
      <FileOpenOutlinedIcon
        ref={ref}
        htmlColor={variables.highlightBad}
        {...props}
      />
    );
  },
);

export const DowngradedIcon = forwardRef<SVGSVGElement>(
  function DowngradedIcon(props, ref) {
    return (
      <AttachMoneyIcon
        ref={ref}
        htmlColor={variables.highlightBad}
        {...props}
      />
    );
  },
);

export const PaidZeroIcon = forwardRef<SVGSVGElement>(
  function PaidZeroIcon(props, ref) {
    return (
      <MoneyOffIcon
        ref={ref}
        htmlColor={variables.highlightVeryBad}
        {...props}
      />
    );
  },
);

export const PaidOkIcon = forwardRef<SVGSVGElement>(
  function PaidOkIcon(props, ref) {
    return (
      <AttachMoneyIcon
        ref={ref}
        htmlColor={variables.highlightVeryGood}
        {...props}
      />
    );
  },
);

export const PaidUnderIcon = forwardRef<HTMLSpanElement>(
  function PaidUnderIcon(props, ref) {
    return (
      <span ref={ref} style={{ position: 'relative' }} {...props}>
        <AttachMoneyIcon htmlColor={variables.highlightWarning} />
        <KeyboardArrowDownIcon
          htmlColor={variables.highlightBad}
          sx={{ position: 'absolute', top: '60%', left: 0 }}
        />
      </span>
    );
  },
);

export const PaidOverIcon = forwardRef<HTMLSpanElement>(
  function PaidOverIcon(props, ref) {
    return (
      <span ref={ref} style={{ position: 'relative' }} {...props}>
        <AttachMoneyIcon htmlColor={variables.highlightVeryGood} />
        <KeyboardArrowUpIcon
          htmlColor={variables.highlightVeryGood}
          sx={{ position: 'absolute', bottom: '60%', left: 0 }}
        />
      </span>
    );
  },
);
