import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import type { Facility } from '../types';

export default function useFacility(facilityID: number | undefined) {
  const fetchFacility = useClientAPIQueryFn<
    Facility,
    ['facilities', number | undefined]
  >(`facilities/${facilityID}`);

  return useQuery({
    queryKey: ['facilities', facilityID],
    enabled: facilityID !== undefined,
    queryFn: fetchFacility,
  });
}
