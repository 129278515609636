import {
  ProductPreviewContext,
  useHasPermission,
  useUserProfile,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  Menu,
  menuClasses,
  MenuItem,
  menuItemClasses,
  PopoverPosition,
  styled,
} from '@mui/material';
import { useContext } from 'react';
import { SnoozePopover } from '../..';
import { useSnoozeBehavior, useUnreadBehavior } from '../../../hooks';
import type { ClaimInfo } from '../../../types';

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    border: variables.borderLight,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
  },
  [`& .${menuItemClasses.root}`]: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: variables.weightMedium,
  },
}));

export default function ContextMenu({
  claim,
  open,
  anchorPosition,
  onClose,
}: {
  claim: ClaimInfo;
  open: boolean;
  anchorPosition: PopoverPosition | null;
  onClose: () => void;
}) {
  const { hasPermission } = useHasPermission();
  const user = useUserProfile();
  const { preview } = useContext(ProductPreviewContext);

  const href = `/claim/${user.client.name}/${claim.id}`;
  const unreadBehavior = useUnreadBehavior(
    claim.id.toString(),
    claim.claim_ui_state,
  );
  const snoozeBehavior = useSnoozeBehavior(
    claim.id.toString(),
    claim.claim_ui_state,
  );

  return (
    <>
      <StyledMenu
        anchorPosition={anchorPosition ?? undefined}
        anchorReference='anchorPosition'
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
      >
        <MenuItem onClick={() => onClose()}>
          <a
            href={href}
            target='_blank'
            rel='noopener noreferrer'
            style={{ display: 'contents', color: 'unset' }}
          >
            Open in new tab
          </a>
        </MenuItem>
        {hasPermission('write:claim-unread') && (
          <MenuItem
            onClick={() => {
              onClose();
              unreadBehavior.toggle();
            }}
            disabled={preview}
          >
            Mark as {unreadBehavior.isUnread ? 'read' : 'unread'}
          </MenuItem>
        )}
        {hasPermission('write:claim-snooze') && (
          <MenuItem
            onClick={() => {
              onClose();
              snoozeBehavior.toggle({
                anchorPosition: anchorPosition ?? undefined,
                anchorReference: 'anchorPosition',
              });
            }}
            disabled={preview}
          >
            {snoozeBehavior.isSnoozed ? 'Wake up' : 'Snooze'}
          </MenuItem>
        )}
      </StyledMenu>
      <SnoozePopover
        onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => e.stopPropagation()}
        {...snoozeBehavior.popoverProps}
      />
    </>
  );
}
