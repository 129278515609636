import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { ErrorPage } from '../components';

export default function NotFound() {
  return (
    <ErrorPage
      Icon={SentimentDissatisfiedIcon}
      headerText={['4', '0', '4']}
      content='Sorry, page not found.'
    />
  );
}
