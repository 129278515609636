import { useUserProfile } from '@insidedesk/tuxedo';
import { useParams } from 'react-router-dom';
import { ClaimDetails } from '../../components';
import { ClaimIdNotFoundError, IncorrectUserError } from '../../error';

export default function ClaimDetailsRoute() {
  const { claimId, clientName } = useParams();
  const { client } = useUserProfile();

  if (claimId === undefined) {
    throw new ClaimIdNotFoundError('Claim id is not specified');
  }

  if (client.name !== clientName) {
    throw new IncorrectUserError();
  }

  return <ClaimDetails claimId={claimId} />;
}
