import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { useCallback } from 'react';
import {
  ClaimDetails,
  ClaimList,
  PatchClaimUIStateVariables,
  WorkflowState,
  Writeable,
} from '../types';
import { MutationBuilder } from '../utils';

function useUiStateMutationFn<TParams>(
  claimId: string,
  paramsToVariables: (params: TParams) => PatchClaimUIStateVariables,
) {
  const mutationFn = useClientAPIMutationFn<void, PatchClaimUIStateVariables>(
    `claims/${claimId}/ui-state`,
    { method: 'PATCH' },
  );
  const mutateByParamsFn = useCallback(
    (params: TParams) => mutationFn(paramsToVariables(params)),
    [mutationFn, paramsToVariables],
  );
  return mutateByParamsFn;
}

export function usePatchWorkflowState(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(
    claimId,
    (workflowState: WorkflowState | null) => ({
      workflow_state_id: workflowState?.id ?? 0,
    }),
  );

  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      ['claims', claimId, 'details'],
      produce((draft: Writeable<ClaimDetails>, workflowState) => {
        if (draft.ui_state) draft.ui_state.workflow_state = workflowState;
      }),
    )
    .invalidate(['claims', claimId, 'history'])
    .invalidate(['claimList'])
    .configure();
  return useMutation(options);
}

export function usePatchDenialReasonIds(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(
    claimId,
    (denialReasonIds: number[]) => ({ denial_reason_ids: denialReasonIds }),
  );
  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      ['claims', claimId, 'denialReasons'],
      (_, denialReasonIds) => denialReasonIds,
    )
    .invalidate(['claims', claimId, 'history'])
    .invalidate(['claimList'])
    .configure();
  return useMutation(options);
}

export function usePatchUnread(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(claimId, (unread: boolean) => ({
    unread,
  }));
  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      ['claims', claimId, 'details'],
      produce((draft: Writeable<ClaimDetails>, unread: boolean) => {
        if (draft.ui_state) draft.ui_state.unread = unread;
      }),
    )
    .optimistic(
      ['claimList'],
      produce((draft: Writeable<ClaimList>, unread: boolean) => {
        draft.page?.forEach((claim) => {
          if (claim.id.toString() === claimId) {
            // eslint-disable-next-line no-param-reassign
            claim.claim_ui_state.unread = unread;
          }
        });
      }),
    )
    .configure();
  return useMutation(options);
}

export function usePatchSnoozed(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(
    claimId,
    (snoozeUntil: Dayjs | null) => ({
      snooze_days: Math.ceil(snoozeUntil?.diff(dayjs(), 'days', true) ?? 0),
    }),
  );
  const builder = new MutationBuilder(queryClient, { mutationFn });
  const detailsKey = ['claims', claimId, 'details'];
  const updateDetails = produce(
    (draft: Writeable<ClaimDetails>, snoozeUntil: Dayjs | null) => {
      if (draft.ui_state)
        draft.ui_state.snooze_until = snoozeUntil?.format() ?? null;
    },
  );
  const listKey = ['claimList'];
  const updateList = produce(
    (draft: Writeable<ClaimList>, snoozeUntil: Dayjs | null) => {
      draft.page?.forEach((claim) => {
        if (claim.id.toString() === claimId) {
          // eslint-disable-next-line no-param-reassign
          claim.claim_ui_state.snooze_until = snoozeUntil?.format() ?? null;
        }
      });
    },
  );
  const optimisticOptions = builder
    .optimistic(detailsKey, updateDetails)
    .optimistic(listKey, updateList)
    .invalidate(['claimList'])
    .configure();
  const delayedOptions = builder
    .delayed(detailsKey, (queryData: ClaimDetails, data, variables) =>
      updateDetails(queryData, variables),
    )
    .delayed(listKey, (queryData: ClaimList, data, variables) =>
      updateList(queryData, variables),
    )
    .invalidate(['claimList'])
    .configure();
  // XXX Bit of a hack, sometimes we want optimistic updates and sometimes not,
  // so return a mutation for both.
  return [useMutation(optimisticOptions), useMutation(delayedOptions)] as const;
}

export function usePatchPaymentType(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(
    claimId,
    (paymentType: NonNullable<PatchClaimUIStateVariables['payment_type']>) => ({
      payment_type: paymentType,
    }),
  );
  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      ['claims', claimId, 'details'],
      produce((draft: Writeable<ClaimDetails>, paymentType) => {
        if (draft.ui_state) draft.ui_state.payment_type = paymentType;
      }),
    )
    .invalidate(['claimList'])
    .configure();
  return useMutation(options);
}

export function usePatchPaymentFrequency(claimId: string) {
  const queryClient = useQueryClient();
  const mutationFn = useUiStateMutationFn(
    claimId,
    (
      paymentFrequency: NonNullable<
        PatchClaimUIStateVariables['payment_frequency']
      >,
    ) => ({
      payment_frequency: paymentFrequency,
    }),
  );
  const options = new MutationBuilder(queryClient, { mutationFn })
    .optimistic(
      ['claims', claimId, 'details'],
      produce((draft: Writeable<ClaimDetails>, paymentFrequency) => {
        if (draft.ui_state) draft.ui_state.payment_frequency = paymentFrequency;
      }),
    )
    .invalidate(['claimList'])
    .configure();
  return useMutation(options);
}
