import {
  inputBaseClasses,
  outlinedInputClasses,
  useTheme,
} from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { claimListFiltersAtom } from '../../state';

export default function DoSRangePicker({
  range,
}: {
  range: 'dosStart' | 'dosEnd';
}) {
  const [error, setError] = useState<DateValidationError | null>(null);
  const [filters, setFilters] = useAtom(claimListFiltersAtom);
  const label = range === 'dosStart' ? 'DoS Start' : 'DoS End';
  const theme = useTheme();

  return (
    <DatePicker
      label={(filters[range] || error) && label}
      value={filters[range] ? filters[range] : null}
      onChange={(value, context) => {
        if (!context.validationError) {
          setFilters({ [range]: value });
        }
      }}
      maxDate={range === 'dosStart' ? filters.dosEnd : null}
      minDate={range === 'dosEnd' ? filters.dosStart : null}
      disableFuture
      onError={setError}
      slotProps={{
        textField: { size: 'small', placeholder: label, fullWidth: true },
        field: { clearable: true },
      }}
      sx={{
        [`& .${inputBaseClasses.root}`]: { pr: '1.5rem' },
        [`& .${outlinedInputClasses.root}`]: {
          '&.Mui-focused fieldset, &:hover fieldset': {
            borderColor: `${error ? theme.palette.error.main : ''} !important`,
          },
        },
      }}
    />
  );
}
