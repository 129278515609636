import { useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';

interface PostFeedbackVariables {
  accurate: boolean;
  body?: string;
}

export default function usePostFeedback(claimId: string) {
  const mutationFn = useClientAPIMutationFn<void, PostFeedbackVariables>(
    `claims/${claimId}/feedback`,
    { method: 'POST' },
  );
  return useMutation({ mutationFn });
}
