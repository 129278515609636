import {
  alpha,
  Chip,
  ChipProps,
  Palette,
  PaletteColor,
  useTheme,
} from '@mui/material';

type PaletteColors = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof Palette];

export type TitleChipProps = Omit<ChipProps, 'color'> & {
  color: PaletteColors;
};

export default function TitleChip(props: TitleChipProps) {
  const { color, sx, ...rest } = props;
  const theme = useTheme();
  const mainColor = theme.palette[color].main;

  return (
    <Chip
      variant='outlined'
      sx={{
        color: mainColor,
        bgcolor: alpha(mainColor, 0.05),
        borderColor: alpha(mainColor, 0.25),
        textTransform: 'uppercase',
        ...sx,
      }}
      {...rest}
    />
  );
}
