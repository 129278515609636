import { useClientAPIQueryFn } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { claimListFiltersAtom, claimListPendingAtom } from '../state';
import { makeClaimSearchParams } from '../utils';

export default function useClaimCounts(claimsTabs: string[]) {
  const filters = useAtomValue(claimListFiltersAtom);
  const pending = useAtomValue(claimListPendingAtom);

  const searchParams = makeClaimSearchParams(
    _.pick(filters, 'facility', 'category'),
    claimsTabs.map((claimsTab) => ['claim_tabs', claimsTab]),
  );

  const queryFn = useClientAPIQueryFn<{ tab: string; totalItems: number }[]>(
    `claims/num-claims/?${searchParams}`,
  );
  return useQuery({
    queryFn,
    queryKey: ['claimList', 'counts', searchParams.toString()],
    select: (data) => {
      const mapping: Map<string, number> = new Map();
      data.forEach(({ tab, totalItems }) => mapping.set(tab, totalItems));
      return mapping;
    },
    enabled: !pending.counts,
  });
}
