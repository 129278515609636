import { useClientAPIQueryFn, useHasPermission } from '@insidedesk/tuxedo';
import { useQuery } from '@tanstack/react-query';
import type { StatusMapping } from '../types';

/**
 * fetch and/or return data from the info/status-mapping endpoint.
 */
export default function useStatusMapping() {
  const { hasPermission } = useHasPermission();
  const queryFn = useClientAPIQueryFn<StatusMapping>('info/status-mapping');
  return useQuery(['statusMapping'], queryFn, {
    enabled: hasPermission('read:claims'),
  });
}
