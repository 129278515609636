import { HTTPError } from '@insidedesk/tuxedo';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { noticeError } from './utils';

// eslint-disable-next-line import/prefer-default-export
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof HTTPError) {
          if (error.response.status === 404 || error.response.status === 401) {
            return false;
          }
        }
        return failureCount < 1;
      },
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (err) => noticeError(err),
  }),
  mutationCache: new MutationCache({
    onError: (err) => noticeError(err),
  }),
});
