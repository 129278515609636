import { Card, CardProps } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { DataExpiredPopup, useDataExpired } from '.';

export default function CardWithExpiredPopup({
  queries,
  children,
  ...rest
}: { queries: UseQueryResult[] } & CardProps) {
  const context = useDataExpired(queries);
  return (
    <Card
      {...rest}
      sx={{
        ...rest.sx,
        position: 'relative',
        isolation: 'isolate',
        zIndex: 0,
        minHeight: context.dialogOpen ? 300 : undefined,
      }}
    >
      <DataExpiredPopup context={context} />
      {children}
    </Card>
  );
}
