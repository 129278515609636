import { ProductPreviewContext, useHasPermission } from '@insidedesk/tuxedo';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useContext, useState } from 'react';
import { ClaimHistoryCard, ClaimNotesCard, RoundedTabList } from '.';
import '../../theme';

export default function ActivityCard({ claimId }: { claimId: string }) {
  const { hasPermission } = useHasPermission();
  const [tab, setTab] = useState<'notes' | 'changelog'>(
    hasPermission('read:claim-notes') ? 'notes' : 'changelog',
  );
  const { preview } = useContext(ProductPreviewContext);

  return (
    <Box data-testid='activity-card'>
      <TabContext value={tab}>
        <RoundedTabList
          bolded
          centered
          onChange={(e, newTab) => setTab(newTab)}
        >
          {hasPermission('read:claim-notes') && (
            <Tab label='Notes' value='notes' disabled={preview} />
          )}
          <Tab label='Change log' value='changelog' disabled={preview} />
        </RoundedTabList>
        {hasPermission('read:claim-notes') && (
          <TabPanel sx={{ p: 0 }} value='notes'>
            <ClaimNotesCard claimId={claimId} />
          </TabPanel>
        )}
        <TabPanel sx={{ p: 0 }} value='changelog'>
          <ClaimHistoryCard claimId={claimId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
