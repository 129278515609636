import { TwoToneHeader } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  Box,
  Card,
  CardContent,
  Stack,
  SvgIconProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { ComponentType, ReactNode } from 'react';

const ERROR_DIALOG_WIDTH = '870px';
const ERROR_DIALOG_HEIGHT = '740px';
const ERROR_MESSAGE_WIDTH = '470px';

type ErrorPageProps = {
  Icon: ComponentType<SvgIconProps>;
  headerText: string[];
  headerSize?: TypographyProps['fontSize'];
  content?: ReactNode;
  action?: ReactNode;
};
export default function ErrorPage({
  Icon,
  headerText,
  headerSize = 128,
  content,
  action,
}: ErrorPageProps) {
  return (
    <Card variant='flat' sx={{ width: '100%', height: '100%' }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          p: 2,
        }}
      >
        <Card
          variant='outlined'
          sx={{
            width: `100%`,
            height: `100%`,
            maxWidth: ERROR_DIALOG_WIDTH,
            maxHeight: ERROR_DIALOG_HEIGHT,
            overflow: 'scroll',
          }}
        >
          <CardContent sx={{ height: '100%' }}>
            <Stack
              height='100%'
              direction='column'
              alignItems='center'
              justifyContent='center'
              spacing={2}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography color='primary' fontSize={150}>
                  <Icon fontSize='inherit' />
                </Typography>
                <TwoToneHeader
                  fontSize={headerSize}
                  fontWeight={variables.weightSemiBold}
                  lineHeight={1}
                  text={headerText}
                />
              </Box>
              {content && (
                <Typography
                  component='div'
                  fontSize='1rem'
                  fontWeight={variables.weightSemiBold}
                  align='center'
                  maxWidth={ERROR_MESSAGE_WIDTH}
                >
                  {content}
                </Typography>
              )}
              {action && (
                <Typography
                  component='div'
                  fontSize='1rem'
                  fontWeight={variables.weightSemiBold}
                  align='center'
                >
                  {action}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
