import { redirect, RouteObject } from 'react-router-dom';
import PermissionGuard from '../components/PermissionGuard';
import { NAVBAR_CONFIG } from '../config';
import ClaimDetails from './ClaimDetailsRoute/ClaimDetailsRoute';
import ClaimList from './ClaimListRoute/ClaimListRoute';
import ErrorWithinRoot from './ErrorWithinRoot';
import NotFound from './NotFoundRoute';
import Root from './Root';
import UnknownError from './UnknownError';

const routes: RouteObject[] = [
  {
    element: <Root />,
    errorElement: <UnknownError />,
    children: [
      { path: '/', loader: () => redirect('/claims') },
      {
        path: '/claims',
        element: (
          <PermissionGuard require='read:claims'>
            <ClaimList />
          </PermissionGuard>
        ),
        errorElement: <ErrorWithinRoot />,
      },
      {
        path: '/claim/:clientName/:claimId',
        element: (
          <PermissionGuard require='read:claims'>
            <ClaimDetails />
          </PermissionGuard>
        ),
        errorElement: <ErrorWithinRoot />,
      },
      // XXX: Legacy redirects
      {
        path: '/settings/insurer-credentials',
        loader: () => redirect(NAVBAR_CONFIG.credentialsUrl),
      },
      {
        path: '/settings/users',
        loader: () => redirect(NAVBAR_CONFIG.usersUrl),
      },
      // XXX: Convenience redirects
      {
        path: '/credentials',
        loader: () => redirect(NAVBAR_CONFIG.credentialsUrl),
      },
      {
        path: '/insurer-credentials',
        loader: () => redirect(NAVBAR_CONFIG.credentialsUrl),
      },
      {
        path: '/insurance-credentials',
        loader: () => redirect(NAVBAR_CONFIG.credentialsUrl),
      },
      {
        path: '/users',
        loader: () => redirect(NAVBAR_CONFIG.usersUrl),
      },
      { path: '*', element: <NotFound /> },
    ],
  },
];
export default routes;
