import {
  capitalizeWord,
  isNotNullish,
  KeyboardShortcutBarrier,
  LabelledCell,
  MultiSelect,
  MultiSelectChips,
  MutationErrorAlert,
  ProductPreviewContext,
  SingleSelect,
  useHasPermission,
} from '@insidedesk/tuxedo';
import {
  Button,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { CardWithExpiredPopup } from '..';
import { SyncIndicator } from '../..';
import {
  PAYMENT_FREQUENCY_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from '../../../constants';
import {
  useClaimDetails,
  useClaimUIState,
  useClientDenialReasons,
  useClientWorkflowStates,
  useDenialReasonIds,
  usePatchClaimDetails,
  usePatchDenialReasonIds,
  usePatchPaymentFrequency,
  usePatchPaymentType,
  usePatchWorkflowState,
  usePostClaimNote,
} from '../../../hooks';
import {
  ClaimCategory,
  ClaimDetails,
  DenialReason,
  WorkflowState,
  WorkflowStateDiscriminator,
} from '../../../types';

import './ClaimStatusCard.scss';

const commonLabelledCellProps = {
  labelProps: { variant: 'caption' },
  valueProps: { variant: 'body1' },
} as const;

export default function ClaimStatusCard({
  claimId,
  workflowDiscriminator,
}: {
  claimId: string;
  workflowDiscriminator: WorkflowStateDiscriminator;
}) {
  const clientWorkflowStatesQuery = useClientWorkflowStates(
    workflowDiscriminator,
  );
  const clientDenialReasonsQuery = useClientDenialReasons();
  const claimUIStateQuery = useClaimUIState(claimId);
  const denialReasonIdsQuery = useDenialReasonIds(claimId);
  return (
    <CardWithExpiredPopup
      data-testid='claim-status-card'
      variant='flat'
      color='accent-secondary'
      queries={[
        clientWorkflowStatesQuery,
        clientDenialReasonsQuery,
        claimUIStateQuery,
        denialReasonIdsQuery,
      ]}
    >
      <CardHeader
        title='Claim Status'
        component='span'
        subheader={<SyncIndicator />}
        subheaderTypographyProps={{ flexDirection: 'row-reverse' }}
      />
      <CardContent>
        <Stack spacing={2}>
          {workflowDiscriminator === 'ortho' && (
            <>
              <ClaimStatusSelect claimId={claimId} />
              <PaymentTypeSelect claimId={claimId} />
            </>
          )}
          <WorkflowStateSelect
            claimId={claimId}
            workflowDiscriminator={workflowDiscriminator}
          />
          {workflowDiscriminator === 'ortho' &&
            claimUIStateQuery.data?.workflow_state?.state ===
              'Insurance Payments Current' && (
              <PaymentFrequencySelect claimId={claimId} />
            )}
          <DenialReasonsSelect claimId={claimId} />
          <ClaimNoteForm claimId={claimId} />
        </Stack>
      </CardContent>
    </CardWithExpiredPopup>
  );
}

function ClaimStatusSelect({ claimId }: { claimId: string }) {
  const { claimDetails, isLoading: queryLoading } = useClaimDetails(claimId);
  const claimDetailsMutation = usePatchClaimDetails(claimId);

  const handleChange = (_: unknown, value: string) => {
    const categories: ClaimCategory[] =
      value === 'resolved'
        ? [...(claimDetails?.categories ?? []), 'resolved']
        : (claimDetails?.categories ?? []).filter((c) => c !== 'resolved');

    claimDetailsMutation.mutate({ categories });
  };

  const claimResolvedValue = useMemo(
    () => (claimDetails?.categories.includes('resolved') ? 'resolved' : 'open'),
    [claimDetails?.categories],
  );
  const { preview } = useContext(ProductPreviewContext);

  return (
    <DropdownSelectWrapper
      fallback={
        claimDetails ? (
          <LabelledCell
            label='Claim Status'
            value={capitalizeWord(claimResolvedValue)}
            {...commonLabelledCellProps}
          />
        ) : null
      }
    >
      <SingleSelect
        data-testid='claim-resolved-select'
        label='Claim Status'
        options={['open', 'resolved']}
        getOptionLabel={(option) => capitalizeWord(option)}
        value={claimResolvedValue}
        onChange={handleChange}
        disabled={claimDetailsMutation.isLoading || queryLoading}
        loading={claimDetailsMutation.isLoading || queryLoading}
        fullWidth
        disableClearable
        readOnly={preview}
        sx={{ pb: 1 }}
      />
      <MutationErrorAlert
        message='Error updating claim status, please try again'
        mutation={claimDetailsMutation}
      />
    </DropdownSelectWrapper>
  );
}

function formatPaymentType(
  paymentType: NonNullable<
    NonNullable<ClaimDetails['ui_state']>['payment_type']
  >,
) {
  return paymentType[0] + paymentType.slice(1).toLowerCase();
}

function PaymentTypeSelect({ claimId }: { claimId: string }) {
  const { data: claimUIState, isLoading: queryLoading } =
    useClaimUIState(claimId);
  const paymentTypeMutation = usePatchPaymentType(claimId);
  const { preview } = useContext(ProductPreviewContext);

  return (
    <DropdownSelectWrapper
      fallback={
        claimUIState?.payment_type ? (
          <LabelledCell
            label='Payment Type'
            value={formatPaymentType(claimUIState.payment_type)}
            {...commonLabelledCellProps}
          />
        ) : null
      }
    >
      <SingleSelect
        data-testid='payment-type-select'
        label='Payment Type'
        options={PAYMENT_TYPE_OPTIONS}
        getOptionLabel={(option) => formatPaymentType(option)}
        value={claimUIState?.payment_type ?? null}
        onChange={(e, value) => value && paymentTypeMutation.mutate(value)}
        disabled={paymentTypeMutation.isLoading || queryLoading}
        loading={paymentTypeMutation.isLoading || queryLoading}
        fullWidth
        readOnly={preview}
        disableClearable={isNotNullish(claimUIState?.payment_type)}
        sx={{ pb: 1 }}
      />
      <MutationErrorAlert
        message='Error updating payment type, please try again'
        mutation={paymentTypeMutation}
      />
    </DropdownSelectWrapper>
  );
}

function formatPaymentFrequency(frequency: number) {
  switch (frequency) {
    case 45:
      return `${frequency} Days (Monthly Insurance Payments)`;
    case 105:
      return `${frequency} Days (Quarterly Insurance Payments)`;
    case 190:
      return `${frequency} Days (Semi-Annual Insurance Payments)`;
    case 375:
      return `${frequency} Days (Annual Insurance Payments)`;
    default:
      return `${frequency} Days`;
  }
}

function PaymentFrequencySelect({ claimId }: { claimId: string }) {
  const { data: claimUIState, isLoading: queryLoading } =
    useClaimUIState(claimId);
  const paymentFrequencyMutation = usePatchPaymentFrequency(claimId);
  const { preview } = useContext(ProductPreviewContext);

  return (
    <DropdownSelectWrapper
      fallback={
        claimUIState?.payment_frequency ? (
          <LabelledCell
            label='Payment Frequency'
            value={formatPaymentFrequency(claimUIState.payment_frequency)}
            {...commonLabelledCellProps}
          />
        ) : null
      }
    >
      <SingleSelect
        data-testid='payment-frequency-select'
        label='Check For Insurance Payment In'
        options={PAYMENT_FREQUENCY_OPTIONS}
        getOptionLabel={(option) => formatPaymentFrequency(option)}
        value={claimUIState?.payment_frequency ?? null}
        onChange={(e, value) => value && paymentFrequencyMutation.mutate(value)}
        disabled={paymentFrequencyMutation.isLoading || queryLoading}
        loading={paymentFrequencyMutation.isLoading || queryLoading}
        fullWidth
        readOnly={preview}
        disableClearable={isNotNullish(claimUIState?.payment_frequency)}
        sx={{
          pb: 1,
          '& .MuiOutlinedInput-notchedOutline':
            claimUIState?.payment_frequency === null
              ? {
                  borderColor: 'error.main',
                }
              : {},
        }}
      />
      {claimUIState?.payment_frequency === null && (
        <Typography
          variant='subtitle1'
          sx={{ color: 'error.main', marginLeft: 3 }}
        >
          Payment frequency is required!
        </Typography>
      )}

      <MutationErrorAlert
        message='Error updating payment frequency, please try again'
        mutation={paymentFrequencyMutation}
      />
    </DropdownSelectWrapper>
  );
}

type WorkflowStateOption = { label: string } & WorkflowState;

const noneWorkflowState: WorkflowStateOption = {
  id: 0,
  label: 'None',
  state: 'None',
  internal_grouping: 'IN_PROGRESS',
  client_grouping: '',
};

function WorkflowStateSelect({
  claimId,
  workflowDiscriminator,
}: {
  claimId: string;
  workflowDiscriminator: WorkflowStateDiscriminator;
}) {
  const { data: clientWorkflowStates, isLoading: queryLoading } =
    useClientWorkflowStates(workflowDiscriminator);
  const { data: claimUIState } = useClaimUIState(claimId);
  const workflowStateMutation = usePatchWorkflowState(claimId);
  const { preview } = useContext(ProductPreviewContext);

  const workflowStateOptions: WorkflowStateOption[] = useMemo(() => {
    const options =
      clientWorkflowStates?.map((workflowState) => ({
        ...workflowState,
        label: workflowState.state,
      })) ?? [];
    options?.unshift(noneWorkflowState);
    return options;
  }, [clientWorkflowStates]);

  const workflowStateValue = useMemo(() => {
    const workflowState = claimUIState?.workflow_state;
    return workflowState
      ? { ...workflowState, label: workflowState.state ?? 'None' }
      : noneWorkflowState;
  }, [claimUIState?.workflow_state]);

  return (
    <DropdownSelectWrapper
      fallback={
        workflowStateValue !== null ? (
          <LabelledCell
            label='Workflow Status'
            value={workflowStateValue.label}
            {...commonLabelledCellProps}
          />
        ) : null
      }
    >
      <SingleSelect
        data-testid='workflow-state-select'
        label='Workflow Status'
        options={workflowStateOptions}
        value={workflowStateValue}
        onChange={(e, value) => workflowStateMutation.mutate(value)}
        disabled={workflowStateMutation.isLoading || queryLoading}
        loading={workflowStateMutation.isLoading || queryLoading}
        fullWidth
        readOnly={preview}
        sx={{ pb: 1 }}
      />
      <MutationErrorAlert
        message='Error setting a status, please try again'
        mutation={workflowStateMutation}
      />
    </DropdownSelectWrapper>
  );
}

function DenialReasonsSelect({ claimId }: { claimId: string }) {
  const clientDenialReasonsQuery = useClientDenialReasons();
  const denialReasonIdsQuery = useDenialReasonIds(claimId);
  const denialReasonIdsMutation = usePatchDenialReasonIds(claimId);

  const [tempDenialReasons, setTempDenialReasons] = useState<
    (DenialReason & { label: string })[]
  >([]);

  const { preview } = useContext(ProductPreviewContext);

  const denialReasonOptions = useMemo(
    () =>
      clientDenialReasonsQuery.data?.map((reason) => ({
        ...reason,
        label: reason.reason,
      })) ?? [],
    [clientDenialReasonsQuery.data],
  );

  useEffect(() => {
    if (denialReasonIdsQuery.data) {
      setTempDenialReasons(
        denialReasonIdsQuery.data
          .map((id) => denialReasonOptions.find((option) => id === option.id))
          .filter(
            (reason): reason is NonNullable<typeof reason> =>
              reason !== undefined,
          ) ?? [],
      );
    }
  }, [denialReasonIdsQuery.data, denialReasonOptions]);

  return (
    <DropdownSelectWrapper
      fallback={
        tempDenialReasons.length > 0 ? (
          <LabelledCell
            label='Denial Reasons'
            value={
              <MultiSelectChips
                values={tempDenialReasons}
                chipProps={{ className: 'inactive-chip' }}
              />
            }
            labelProps={{ variant: 'body1' }}
            labelFirst
          />
        ) : null
      }
    >
      <MultiSelect
        data-testid='denial-reason-select'
        label='Select Denial Reason(s)'
        options={denialReasonOptions}
        values={tempDenialReasons}
        groupBy={(option) => option.client_grouping.replace(/_/g, ' ')}
        onClose={() => {
          if (!preview) {
            denialReasonIdsMutation.mutate(
              tempDenialReasons.map((option) => option.id),
            );
          }
        }}
        onChange={(e, values, reason) => {
          if (reason === 'removeChip') {
            denialReasonIdsMutation.mutate(values.map((option) => option.id));
          }
          setTempDenialReasons(values);
        }}
        disabled={
          denialReasonIdsMutation.isLoading ||
          clientDenialReasonsQuery.isLoading ||
          denialReasonIdsQuery.isLoading
        }
        loading={
          denialReasonIdsMutation.isLoading ||
          clientDenialReasonsQuery.isLoading ||
          denialReasonIdsQuery.isLoading
        }
        fullWidth
        readOnly={preview}
        sx={{ pb: 1 }}
      />
      <MutationErrorAlert
        message='Error setting a denial, please try again'
        mutation={denialReasonIdsMutation}
      />
    </DropdownSelectWrapper>
  );
}

/**
 * @param fallback - The component to render if the user does not have permission to edit the claim
 */
function DropdownSelectWrapper({
  children,
  fallback,
}: PropsWithChildren<{ fallback?: React.ReactNode }>) {
  const { hasPermission } = useHasPermission();

  return hasPermission('write:rcm') ? (
    <KeyboardShortcutBarrier>{children}</KeyboardShortcutBarrier>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{fallback}</>
  );
}

function ClaimNoteForm({ claimId }: { claimId: string }) {
  const claimNotesMutation = usePostClaimNote(claimId);
  const { hasPermission } = useHasPermission();

  const formContext = useForm({
    defaultValues: { body: '' },
    mode: 'onChange',
  });

  const { preview } = useContext(ProductPreviewContext);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasPermission('write:claim-notes') && (
        <FormContainer
          formContext={formContext}
          onSuccess={(data) =>
            claimNotesMutation.mutate(data, {
              onSuccess: () => formContext.reset(),
            })
          }
        >
          <Stack spacing={2}>
            <KeyboardShortcutBarrier>
              <TextFieldElement
                fullWidth
                name='body'
                InputProps={{ inputProps: { 'aria-label': 'Note Body' } }}
                multiline
                placeholder='Once a note is added, the button below will become active...'
                required
                disabled={claimNotesMutation.isLoading || preview}
              />
            </KeyboardShortcutBarrier>
            <MutationErrorAlert
              message='Error adding a note, please try again'
              mutation={claimNotesMutation}
            />
            <Button
              fullWidth
              variant='contained'
              type='submit'
              disabled={
                claimNotesMutation.isLoading ||
                !formContext.formState.isValid ||
                preview
              }
            >
              Add Note
            </Button>
          </Stack>
        </FormContainer>
      )}
    </>
  );
}
